import React from 'react'
import styles from './Logo.module.scss'
import icon from 'assets/images/logo.svg'
import PropTypes from 'prop-types'
const Logo = (props) => {
  const { variant, className } = props
  return (
    <div className={`${styles.logo} ${styles[variant]} ${className}`}>
      <img src={icon} alt="logo" />
      <span>Lemonclub</span>
    </div>
  )
}

export default Logo
Logo.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  darkTheme: PropTypes.bool,
}
