import React, { useEffect, useState } from 'react'
import Styles from './purchase.module.scss'
import { icon_items } from '../../../pages/ResiDashboard/icon-item'
import Logo from '../../../assets/images/logo.svg'
import GeoSurfLogo from '../../../assets/images/geo-surf.png'
import BrightDataLogo from '../../../assets/images/bright-data.png'
import IpRoyalLogo from '../../../assets/images/Lemonroyal.svg'
import LemonGreenLogo from '../../../assets/images/lemon-green.png'
import LemonLabsLogo from '../../../assets/images/lemon-labs.png'
import NetNutLogo from '../../../assets/images/lemon-nut.png'
import LemonPrimeLogo from '../../../assets/images/lemon-prime.png'
import LemonTopia from '../../../assets/images/lemon-topia.png'
import PacketStreamLogo from '../../../assets/images/packet-stream.png'
import SelectInput from '../../UI/SelectInput'
import checkCircle from '../../../assets/images/signIn/check-circle.png'
import AnimationButton from '../../UI/AnimationButton'
import AnimationButtonGo from 'components/UI/AnimationButtonGo'

const Purchase = (props) => {
  const { membershipType, user, cookies } = props
  const [loading, setLoading] = useState(false)
  const [selectedPlanPurchase, setSelectedPlanPurchase] = useState()
  const [priceResi, setPriceResi] = useState('')
  const [fromPrice, setFromPrice] = useState(0)
  const [selectedAmount, setSelectedAmount] = useState('')
  const [selectedFeature, setSelectedFeature] = useState([])
  const [PaymentMethod, setPaymentMethod] = useState('stripe')
  const [button1Clicked, setButton1Clicked] = useState(true)
  const [button2Clicked, setButton2Clicked] = useState(false)
  const [referral, setReferral] = useState(null)

  const Logos = {
    LemonLabs: LemonLabsLogo,
    LemonBright: BrightDataLogo,
    LemonGreen: LemonGreenLogo,
    LemonNut: NetNutLogo,
    LemonBlue: IpRoyalLogo,
    LemonPrime: LemonPrimeLogo,
    LemonStream: PacketStreamLogo,
    LemonTopia: LemonTopia,
  }

  const plans_features = {
    LemonLabs: [
      'Speed 100ms - 300 ms',
      '14 million of Dedicated IPs',
      'Private Residential Plan',
      'World-Wide Locations Supported',
    ],
    LemonBright: [
      'Speed 200ms - 400 ms',
      '72 million IPs',
      'One of the Biggest Proxy Provider of the World',
      'World-Wide Locations Supported',
    ],
    LemonGreen: [
      'Speed 200ms - 400 ms',
      '200 million IPs',
      'World-Wide Locations Supported',
      'All Websites Supported',
    ],
    LemonNut: [
      'Speed 100ms - 300 ms',
      '10 million ISP Proxy',
      'Hybrid Solution of ISP and Residential Proxy',
      'UK & US Location Supported',
    ],
    LemonBlue: [
      'Speed 500ms - 1000 ms',
      '2 million IPs',
      'One of Cheapest Providers of the World',
      'WorldWide Location Supported',
    ],
    LemonPrime: [
      'Speed 100ms - 500 ms',
      'Static IP Session',
      'Private Residential Plan',
      '35 million Dedicated IPs',
    ],
    LemonStream: [
      'Speed 500ms - 1000 ms',
      '7 million IPs',
      'Cheapest Provider of the World',
      'WorldWide Location Supported',
    ],
    LemonTopia: [
      'Bypass for HTTP Blocked',
      'Speed 200ms-500ms',
      'World Wide Locations',
      'No Expiry',
    ],
  }

  const calculatePriceResi = (selectedPlanPurchase, selectedAmount) => {
    let calculatedPriceResi = 0

    if (selectedPlanPurchase === 'LemonStream') {
      if (selectedAmount === '10') {
        calculatedPriceResi = 10
      } else if (selectedAmount === '50') {
        calculatedPriceResi = 50
      } else if (selectedAmount === '100') {
        calculatedPriceResi = 100
      } else if (selectedAmount === '200') {
        calculatedPriceResi = 200
      }
    } else {
      // Other plans pricing
      const pricePerGB = {
        LemonLabs: membershipType === 'paid' ? 3.9 : 5.9,
        LemonBright: 4.25,
        LemonGreen: 8.5,
        LemonNut: 4.0,
        LemonBlue: 2,
        LemonPrime: 2.5,
        LemonTopia: 2.5,
      }
      const selectedPlanPrice = pricePerGB[selectedPlanPurchase]
      calculatedPriceResi = selectedPlanPrice * parseFloat(selectedAmount)
    }

    setPriceResi(calculatedPriceResi || 0)
  }

  const dataValues = [
    { label: '' },
    { label: 1 },
    { label: 5 },
    { label: 10 },
    { label: 20 },
    { label: 50 },
    { label: 100 },
  ]

  const packetStreamDataValues = [
    { label: '' },
    { label: 10 },
    { label: 50 },
    { label: 100 },
    { label: 200 },
  ]

  useEffect(() => {
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/fetchMembershipStatus', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: `${accessToken}`,
      }),
    })
      .then((response) => {
        return response.text()
      })
      .then((data) => {
        const fromPrice = data === 'paid' ? 3.9 : 5.9
        setFromPrice(fromPrice)
      })
  }, [])

  const plans_fromPrice = {
    LemonLabs: membershipType === 'paid' ? 3.9 : 5.9,
    LemonBright: 4.25,
    LemonGreen: 8.5,
    LemonNut: 4.0,
    LemonBlue: 2,
    LemonPrime: 2.5,
    LemonStream: 1,
    LemonTopia: 2.5,
  }

  const setInitialPlan = () => {
    const plan = document.getElementById('plan').value
    setSelectedPlanPurchase(plan)
    setFeatureResi(plan)
    setPriceResi('0')
  }

  useEffect(() => {
    setInitialPlan()

    props.setIsLoading(false)
  }, [])

  const handleResiPurchase = () => {
    if (selectedAmount === '') {
      alert('Please select Amount')
      return
    }

    setLoading(true)
    // Return a promise that represents the purchase process
    return new Promise((resolve, reject) => {
      const accessToken = cookies.get('access_token')

      if (!selectedPlanPurchase || !selectedAmount) {
        alert("Error! You didn't fill out all fields.")
        setLoading(false) // Set loading to false since the purchase failed
        reject()
        return
      }

      fetch(
        'https://dashboard-api.lemonclub.io/api/v1/createResidentialOrder',
        {
          method: 'POST',
          body: JSON.stringify({
            discord_token: accessToken,
            proxy_type: selectedPlanPurchase,
            proxy_amount: selectedAmount,
            membership_type: membershipType,
            email: `${user.email}`,
            payment_method: PaymentMethod,
            referral: referral,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          window.location.href = data['purchase_url']
          resolve()
        })
        .catch((error) => {
          console.error(error)
          setLoading(false) // Set loading to false since the purchase failed
          reject()
        })
    })
  }

  const handleBilling = () => {
    const accessToken = cookies.get('access_token')
    fetch(
      'https://dashboard-api.lemonclub.io/api/v1/createCustomerPortalSession',
      {
        method: 'POST',
        body: JSON.stringify({
          discord_token: `${accessToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data['portal_session_url']
      })
  }

  const [checked, setChecked] = React.useState(false)

  const handleChange = () => {
    setChecked(!checked)
  }

  const handlePlanChange = (event) => {
    setSelectedPlanPurchase(event.target.value)
    setSelectedAmount('')
    setPriceResi('')
    setFromPrice(plans_fromPrice[event.target.value])
    setFeatureResi(event.target.value)
    calculatePriceResi(event.target.value, selectedAmount)
  }

  const setFeatureResi = (selectedPlanPurchase) => {
    setSelectedFeature(plans_features[selectedPlanPurchase])
  }

  const handleDateValue = (event) => {
    setSelectedAmount(event.target.value)
    calculatePriceResi(selectedPlanPurchase, event.target.value)
  }

  const handleButton1Click = (event) => {
    setPaymentMethod('stripe')
    setButton1Clicked(true)
    setButton2Clicked(false)
  }

  const handleButton2Click = () => {
    setPaymentMethod('crypto')
    setButton1Clicked(false)
    setButton2Clicked(true)
  }
  //<option className='purchase-option' hidden={false} value='LemonGreen'>LemonGreen</option>
  return (
    <div className={`purchase-container ${Styles.container}`}>
      <div className={Styles.brand}>
        {icon_items.purchase}
        <span>Purchase Plans</span>
      </div>
      <select
        id={'plan'}
        className={Styles.selectPlan}
        value={selectedPlanPurchase}
        onChange={(event) => {
          handlePlanChange(event)
        }}
      >
        <option className="purchase-option" hidden={false} value="LemonLabs">
          LemonLabs
        </option>
        <option className="purchase-option" hidden={false} value="LemonBright">
          LemonBright
        </option>

        <option className="purchase-option" hidden={false} value="LemonNut">
          LemonNut
        </option>
        <option className="purchase-option" hidden={false} value="LemonBlue">
          LemonBlue
        </option>
        <option className="purchase-option" hidden={false} value="LemonPrime">
          LemonPrime
        </option>
        <option className="purchase-option" hidden={false} value="LemonStream">
          LemonStream
        </option>
        <option className="purchase-option" hidden={false} value="LemonTopia">
          LemonTopia
        </option>
      </select>

      <div className={Styles.lab}>
        <span>
          {selectedPlanPurchase === 'LemonBlue'
            ? 'LemonBlue'
            : selectedPlanPurchase}
        </span>

        <div className={Styles.labList}>
          <div className={Styles.labList}>
            {selectedFeature.map((item, index) => (
              <div className={Styles.labItem} key={index}>
                <img src={checkCircle} alt="check-circle" />
                <div className={`main-text ${Styles.moreItemText}`}>{item}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={Styles.from}>From</div>

        <div className={Styles.labInfo}>
          <span>${fromPrice}/GB</span>

          <AnimationButtonGo
            width="142px"
            height="32px"
            background="var(--re-white-button-bg)"
            color="var(--re-white-button-color)"
            size="13px"
            borderRadius="10px"
            button="dark"
            label="Learn More"
            goTo="https://discord.gg/lemonclub"
            isNewTab="_blank"
          />
        </div>

        <img className={Styles.logo} src={Logos[selectedPlanPurchase]} />
      </div>

      <div className={Styles.amount}>
        <div className={Styles.title}>
          {icon_items.data}

          <span>Data Amount</span>
        </div>

        <SelectInput
          background="var(--hover-bg-color)"
          option={
            selectedPlanPurchase === 'LemonStream'
              ? packetStreamDataValues
              : dataValues
          }
          onSelectChange={handleDateValue}
          isGB={true}
        />

        <div className={Styles.paymentLabel}>
          <span>Payment Method</span>
        </div>
        <div className={Styles.paymentMethod}>
          <button
            onClick={handleButton1Click}
            className={
              button1Clicked ? Styles.buttonClicked : Styles.buttonNotClicked
            }
          >
            <span>Stripe</span>
          </button>
          <button
            onClick={handleButton2Click}
            className={
              button2Clicked ? Styles.buttonClicked : Styles.buttonNotClicked
            }
          >
            <span>Crypto</span>
          </button>
        </div>

        <div className={Styles.dataLabel}>
          <span>Total</span>

          <span>Price per GB</span>
        </div>

        <div className={Styles.price}>
          <span>${priceResi}</span>

          <span>${fromPrice}</span>
        </div>

        <div className={Styles.button}>
          <AnimationButton
            width="calc((100% - 11px) / 2)"
            height="42px"
            background="var(--re-white-yellow-bg)"
            color="#FFF"
            size="16px"
            borderRadius="12px"
            button="yellow"
            label={!loading ? 'Purchase' : 'Loading...'}
            handler={handleResiPurchase}
          />

          <AnimationButton
            width="calc((100% - 11px) / 2)"
            height="42px"
            background="var(--re-black-yellow-bg)"
            color="var(--font-color)"
            size="16px"
            borderRadius="12px"
            button="special"
            label="Billing"
            handler={handleBilling}
          />
        </div>
      </div>
    </div>
  )
}

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label>
      <input type="checkbox" checked={value} onChange={onChange} />
      {label}
    </label>
  )
}

export default Purchase
