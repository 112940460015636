export const icon_items = {
  moreAbout: (
    <svg viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Light-Pink-24">
        <g id="Glass" filter="url(#filter0_bi_1_4083)">
          <path
            d="M82.3311 119.303C76.5876 122.455 69.4124 122.455 63.6689 119.303L21.1851 95.9909C17.2716 93.8435 17.2716 88.6564 21.1851 86.509L63.6689 63.197C69.4124 60.0454 76.5876 60.0454 82.3311 63.197L124.815 86.509C128.728 88.6564 128.728 93.8435 124.815 95.9909L82.3311 119.303Z"
            fill="#F1F1F1"
            fillOpacity="0.1"
          />
          <path
            d="M82.3311 119.303C76.5876 122.455 69.4124 122.455 63.6689 119.303L21.1851 95.9909C17.2716 93.8435 17.2716 88.6564 21.1851 86.509L63.6689 63.197C69.4124 60.0454 76.5876 60.0454 82.3311 63.197L124.815 86.509C128.728 88.6564 128.728 93.8435 124.815 95.9909L82.3311 119.303Z"
            fill="var(--si-more-icon)"
            fillOpacity="0.2"
          />
          <path
            d="M82.0385 118.77C76.4773 121.821 69.5227 121.821 63.9615 118.77L21.4778 95.4576C17.9852 93.5411 17.9852 88.9588 21.4778 87.0423L63.9615 63.7303C69.5227 60.6787 76.4773 60.6787 82.0385 63.7303L124.522 87.0423C128.015 88.9588 128.015 93.5411 124.522 95.4576L82.0385 118.77Z"
            stroke="url(#paint1_linear_1_4083)"
            strokeOpacity="0.1"
            strokeWidth="1.21667"
          />
        </g>
        <g id="Glass_2">
          <path
            d="M82.3311 101.053C76.5876 104.205 69.4124 104.205 63.6689 101.053L21.1851 77.7409C17.2716 75.5935 17.2716 70.4064 21.1851 68.259L63.6689 44.947C69.4124 41.7954 76.5876 41.7954 82.3311 44.947L124.815 68.259C128.728 70.4064 128.728 75.5935 124.815 77.7409L82.3311 101.053Z"
            fill="url(#paint2_linear_1_4083)"
          />
          <path
            d="M82.3311 101.053C76.5876 104.205 69.4124 104.205 63.6689 101.053L21.1851 77.7409C17.2716 75.5935 17.2716 70.4064 21.1851 68.259L63.6689 44.947C69.4124 41.7954 76.5876 41.7954 82.3311 44.947L124.815 68.259C128.728 70.4064 128.728 75.5935 124.815 77.7409L82.3311 101.053Z"
            fill="url(#paint3_linear_1_4083)"
          />
          <path
            d="M82.3311 101.053C76.5876 104.205 69.4124 104.205 63.6689 101.053L21.1851 77.7409C17.2716 75.5935 17.2716 70.4064 21.1851 68.259L63.6689 44.947C69.4124 41.7954 76.5876 41.7954 82.3311 44.947L124.815 68.259C128.728 70.4064 128.728 75.5935 124.815 77.7409L82.3311 101.053Z"
            fill="url(#paint4_linear_1_4083)"
          />
          <path
            d="M82.3311 101.053C76.5876 104.205 69.4124 104.205 63.6689 101.053L21.1851 77.7409C17.2716 75.5935 17.2716 70.4064 21.1851 68.259L63.6689 44.947C69.4124 41.7954 76.5876 41.7954 82.3311 44.947L124.815 68.259C128.728 70.4064 128.728 75.5935 124.815 77.7409L82.3311 101.053Z"
            fill="url(#paint5_linear_1_4083)"
          />
        </g>
        <g id="Color" filter="url(#filter1_bi_1_4083)">
          <path
            d="M82.3311 82.8029C76.5876 85.9545 69.4124 85.9545 63.6689 82.8029L21.1851 59.4909C17.2716 57.3435 17.2716 52.1564 21.1851 50.009L63.6689 26.697C69.4124 23.5454 76.5876 23.5454 82.3311 26.697L124.815 50.009C128.728 52.1564 128.728 57.3435 124.815 59.4909L82.3311 82.8029Z"
            fill="#F1F1F1"
            fillOpacity="0.1"
          />
          <path
            d="M82.3311 82.8029C76.5876 85.9545 69.4124 85.9545 63.6689 82.8029L21.1851 59.4909C17.2716 57.3435 17.2716 52.1564 21.1851 50.009L63.6689 26.697C69.4124 23.5454 76.5876 23.5454 82.3311 26.697L124.815 50.009C128.728 52.1564 128.728 57.3435 124.815 59.4909L82.3311 82.8029Z"
            fill="var(--si-more-icon)"
            fillOpacity="0.2"
          />
          <path
            d="M82.0385 82.2696C76.4773 85.3212 69.5227 85.3212 63.9615 82.2696L21.4778 58.9576C17.9852 57.0411 17.9852 52.4588 21.4778 50.5423L63.9615 27.2303C69.5227 24.1787 76.4773 24.1787 82.0385 27.2303L124.522 50.5423C128.015 52.4588 128.015 57.0411 124.522 58.9576L82.0385 82.2696Z"
            stroke="url(#paint7_linear_1_4083)"
            strokeOpacity="0.1"
            strokeWidth="1.21667"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_bi_1_4083"
          x="0"
          y="42.5833"
          width="146"
          height="97.3334"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.125" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1_4083"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1_4083"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.04167" />
          <feGaussianBlur stdDeviation="6.08333" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_1_4083"
          />
        </filter>
        <filter
          id="filter1_bi_1_4083"
          x="0"
          y="6.08325"
          width="146"
          height="97.3334"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.125" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1_4083"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1_4083"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.04167" />
          <feGaussianBlur stdDeviation="6.08333" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_1_4083"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_4083"
          x1="127.75"
          y1="91.2297"
          x2="18.25"
          y2="91.2297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A53BB" />
          <stop offset="1" stopColor="#505EDC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_4083"
          x1="124.328"
          y1="118.901"
          x2="18.25"
          y2="118.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD84DC" />
          <stop offset="1" stopColor="#7FD6FA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_4083"
          x1="123.188"
          y1="91.25"
          x2="8.6777"
          y2="62.6225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE82DB" />
          <stop offset="1" stopColor="#68E4FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_4083"
          x1="127.75"
          y1="72.9797"
          x2="18.25"
          y2="72.9797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#474EEB" />
          <stop offset="1" stopColor="#7177EC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_4083"
          x1="127.75"
          y1="72.9797"
          x2="18.25"
          y2="72.9797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A53BB" />
          <stop offset="1" stopColor="#505EDC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1_4083"
          x1="24.966"
          y1="44.2702"
          x2="55.6502"
          y2="103.705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA329" />
          <stop offset="0.3574" stopColor="#FE7B1C" />
          <stop offset="1" stopColor="#FC2A01" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1_4083"
          x1="127.75"
          y1="54.7297"
          x2="18.25"
          y2="54.7297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A53BB" />
          <stop offset="1" stopColor="#505EDC" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1_4083"
          x1="124.328"
          y1="82.4015"
          x2="18.25"
          y2="82.4015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD84DC" />
          <stop offset="1" stopColor="#7FD6FA" />
        </linearGradient>
      </defs>
    </svg>
  ),

  textLogo: (
    <svg viewBox="0 0 162 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="logotext 1" clipPath="url(#clip0_16_4158)">
        <path
          id="Vector"
          d="M45.7754 26.1H56.6153V23.2488H49.1985V10.98H45.7754V26.1Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_2"
          d="M68.4683 20.3328C68.4683 16.596 65.8904 14.3064 62.5517 14.3064C59.0864 14.3064 56.4873 16.812 56.4873 20.2896C56.4873 23.7456 59.0441 26.2728 62.9744 26.2728C65.024 26.2728 66.6088 25.6248 67.6653 24.3936L65.9115 22.4496C65.1297 23.2056 64.2633 23.5728 63.0589 23.5728C61.3262 23.5728 60.1217 22.6872 59.8048 21.24H68.4049C68.426 20.9592 68.4683 20.592 68.4683 20.3328ZM62.5729 16.8552C64.052 16.8552 65.1297 17.8056 65.3621 19.2744H59.7625C59.995 17.784 61.0726 16.8552 62.5729 16.8552Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_3"
          d="M84.3414 14.3064C82.6298 14.3064 81.1507 15.0192 80.2421 16.2072C79.4391 14.9328 78.0868 14.3064 76.502 14.3064C75.044 14.3064 73.7762 14.8248 72.931 15.8184V14.4792H69.7825V26.1H73.0789V20.268C73.0789 18.1944 74.1143 17.2224 75.5723 17.2224C76.9457 17.2224 77.7276 18.0864 77.7276 19.9656V26.1H81.0239V20.268C81.0239 18.1944 82.0593 17.2224 83.5384 17.2224C84.8697 17.2224 85.6937 18.0864 85.6937 19.9656V26.1H88.9901V19.4472C88.9901 15.9048 87.0461 14.3064 84.3414 14.3064Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_4"
          d="M96.5974 26.2728C100.211 26.2728 102.831 23.7888 102.831 20.2896C102.831 16.7904 100.211 14.3064 96.5974 14.3064C92.9841 14.3064 90.3428 16.7904 90.3428 20.2896C90.3428 23.7888 92.9841 26.2728 96.5974 26.2728ZM96.5974 23.508C94.9492 23.508 93.6814 22.2984 93.6814 20.2896C93.6814 18.2808 94.9492 17.0712 96.5974 17.0712C98.2456 17.0712 99.4923 18.2808 99.4923 20.2896C99.4923 22.2984 98.2456 23.508 96.5974 23.508Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_5"
          d="M111.063 14.3064C109.521 14.3064 108.19 14.8464 107.302 15.84V14.4792H104.154V26.1H107.45V20.3544C107.45 18.216 108.591 17.2224 110.176 17.2224C111.634 17.2224 112.479 18.0864 112.479 19.9656V26.1H115.776V19.4472C115.776 15.9048 113.747 14.3064 111.063 14.3064Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_6"
          d="M123.501 26.2728C125.889 26.2728 127.769 25.1928 128.593 23.3352L126.036 21.9096C125.424 23.0328 124.515 23.508 123.48 23.508C121.81 23.508 120.5 22.32 120.5 20.2896C120.5 18.2592 121.81 17.0712 123.48 17.0712C124.515 17.0712 125.424 17.568 126.036 18.6696L128.593 17.2656C127.769 15.3648 125.889 14.3064 123.501 14.3064C119.803 14.3064 117.162 16.7904 117.162 20.2896C117.162 23.7888 119.803 26.2728 123.501 26.2728Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_7"
          d="M129.528 26.1H132.825V10.0728H129.528V26.1Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_8"
          d="M143.16 14.4792V20.2248C143.16 22.3632 142.061 23.3568 140.54 23.3568C139.082 23.3568 138.236 22.4928 138.236 20.5488V14.4792H134.94V21.0456C134.94 24.6312 136.947 26.2728 139.8 26.2728C141.195 26.2728 142.462 25.7328 143.329 24.7176V26.1H146.456V14.4792H143.16Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_9"
          d="M155.349 14.3064C153.933 14.3064 152.771 14.76 151.926 15.7104V10.0728H148.629V26.1H151.778V24.7608C152.602 25.776 153.828 26.2728 155.349 26.2728C158.54 26.2728 161.012 23.94 161.012 20.2896C161.012 16.6392 158.54 14.3064 155.349 14.3064ZM154.778 23.508C153.13 23.508 151.884 22.2984 151.884 20.2896C151.884 18.2808 153.13 17.0712 154.778 17.0712C156.427 17.0712 157.673 18.2808 157.673 20.2896C157.673 22.2984 156.427 23.508 154.778 23.508Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_10"
          d="M21.5033 28.6729C14.9557 32.9577 6.40413 32.3166 0.728569 27.1248C-1.50494 19.6811 1.52407 11.4868 8.07161 7.20199C14.6192 2.91719 23.1707 3.55835 28.8463 8.75015C31.0798 16.1938 28.0661 24.3881 21.5033 28.6729Z"
          fill="url(#paint0_linear_16_4158)"
        />
        <path
          id="Vector_11"
          opacity="0.5"
          d="M3.86443 28.4853C5.66404 28.4853 7.12291 26.994 7.12291 25.1544C7.12291 23.3148 5.66404 21.8235 3.86443 21.8235C2.06483 21.8235 0.605957 23.3148 0.605957 25.1544C0.605957 26.994 2.06483 28.4853 3.86443 28.4853Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_12"
          d="M26.9797 20.6038C25.5417 27.4845 18.9024 31.8631 12.1866 30.3931C9.92247 29.8927 7.94903 28.7981 6.35804 27.3125C3.22195 24.3569 1.64625 19.8375 2.61003 15.2712C4.04804 8.39053 10.6874 4.01191 17.4032 5.48187C19.499 5.95101 21.3654 6.92057 22.8952 8.26543C23.0176 8.3749 23.1399 8.48436 23.247 8.59383C26.3831 11.5338 27.9435 16.0531 26.9797 20.6038Z"
          fill="url(#paint1_linear_16_4158)"
        />
        <path
          id="Vector_13"
          opacity="0.5"
          d="M25.7404 14.067C27.54 14.067 28.9989 12.5758 28.9989 10.7362C28.9989 8.89656 27.54 7.40527 25.7404 7.40527C23.9408 7.40527 22.4819 8.89656 22.4819 10.7362C22.4819 12.5758 23.9408 14.067 25.7404 14.067Z"
          fill="var(--font-color)"
        />
        <path
          id="Vector_14"
          d="M23.2623 8.5782C17.6327 7.38972 11.9265 10.2515 9.44823 15.4433C7.06175 20.4318 8.1632 26.5306 12.1866 30.3775C9.92247 29.8771 7.94903 28.7824 6.35804 27.2968C3.22195 24.3413 1.64625 19.8219 2.61003 15.2556C4.04804 8.37491 10.6874 3.99628 17.4032 5.46625C19.2849 5.87284 20.9676 6.85803 20.9676 6.85803C21.9773 7.45227 22.7422 8.09343 23.2623 8.5782Z"
          fill="url(#paint2_linear_16_4158)"
        />
        <path
          id="Vector_15"
          d="M32.8698 4.82503C32.5027 6.34191 31.5695 7.57731 30.4833 7.96826C29.7184 7.10817 29.4584 5.56001 29.8255 4.04313C30.1927 2.52625 31.1259 1.29085 32.212 0.899902C32.9769 1.75999 33.237 3.30815 32.8698 4.82503Z"
          fill="url(#paint3_linear_16_4158)"
        />
        <path
          id="Vector_16"
          d="M36.1435 12.9098C33.6194 12.2686 31.5694 10.6423 30.9116 8.76572C32.3496 7.42086 34.9044 6.983 37.4286 7.62415C39.9527 8.26531 42.0027 9.89166 42.6605 11.7682C41.2378 13.0974 38.6677 13.5509 36.1435 12.9098Z"
          fill="url(#paint4_linear_16_4158)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_16_4158"
          x1="7.06309"
          y1="7.90998"
          x2="22.9473"
          y2="27.6214"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0160547" stopColor="#3A37D1" />
          <stop offset="1" stopColor="#EA50BF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_4158"
          x1="25.7334"
          y1="29.9707"
          x2="9.9901"
          y2="13.3919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA329" />
          <stop offset="0.3574" stopColor="#FE7B1C" />
          <stop offset="1" stopColor="#FC2A01" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_16_4158"
          x1="21.197"
          y1="3.97217"
          x2="5.16831"
          y2="23.7429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA50BF" />
          <stop offset="1" stopColor="#FFA329" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_16_4158"
          x1="32.2301"
          y1="1.09943"
          x2="30.4303"
          y2="7.36921"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0160547" stopColor="#3A37D1" />
          <stop offset="1" stopColor="#EA50BF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_16_4158"
          x1="42.3209"
          y1="11.7863"
          x2="31.8225"
          y2="8.9021"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0160547" stopColor="#3A37D1" />
          <stop offset="1" stopColor="#EA50BF" />
        </linearGradient>
        <clipPath id="clip0_16_4158">
          <rect width="162" height="36" fill="var(--font-color)" />
        </clipPath>
      </defs>
    </svg>
  ),
}
