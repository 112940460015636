import React, { useEffect, useState } from 'react'
import Styles from './generate.module.scss'
import { icon_items } from '../../../pages/ResiDashboard/icon-item'
import AnimationButton from '../../UI/AnimationButton'
import Switch from '../../UI/Switch'
import ProxyList from '../proxyList'
import regionList from 'constants/regionList'
import {
  successHandler,
  errorHandler,
  copyHandler,
  downloadHandler,
} from 'helpers'
import SearchInput from 'components/UI/SearchInput'
import { useSelector, useDispatch, connect } from 'react-redux'

const Generate = (props) => {
  const { cookies } = props

  const [showAlertGen, setShowAlertGen] = useState(false)
  const [loadingGen, setLoadingGen] = useState(false)
  const [proxyList, setProxyList] = useState([])
  const [proxySticky, setProxySticky] = useState('')
  const [proxyCount, setProxyCount] = useState(0)
  const [region, setRegion] = useState('')
  const [performancePool, setPerformancePool] = useState(undefined)

  const dispatch = useDispatch()
  function getProxyList(proxyLists) {
    dispatch({ type: 'GENERATING_PROXY_LIST', payload: proxyLists })
  }

  const generateProxies = () => {
    if (region === '') {
      alert('Please select region')
      return
    }

    if (props.selectedPlan === '') {
      alert('Please select plan in Analytics')
      return
    }

    if (proxySticky === '') {
      alert('Please select plan Sticky')
      return
    }

    if (proxyCount === 0) {
      alert('Please select count')
      return
    }

    setLoadingGen(true)
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/generateResidential', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: accessToken,
        proxy_sticky: `${proxySticky}`,
        proxy_type: `${props.selectedPlan}`,
        proxy_amount: proxyCount,
        proxy_pool: `${region}`,
        performance_pool: `${performancePool}`,
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        setShowAlertGen(false)
        setLoadingGen(false)
        setProxyList([])
        const proxies = data.split('\n') // Split the response into an array of proxies
        const temp_proxies = [...proxies]
        temp_proxies.pop()
        setProxyList(temp_proxies)
        getProxyList(temp_proxies)
        successHandler('Generating Proxies Successfully!')
      })
      .catch((error) => {
        console.error('Error:', error)
        errorHandler('Error Generating Proxies, please try again later!')
      })
  }

  const resetHandler = () => {
    setProxyList([])
    setProxySticky('')
    setProxyCount(0)
    setRegion('')
  }

  const resetProxyPassword = () => {
    if (props.selectedPlan === '') {
      alert('Please select plan in Analytics')
      return
    }

    const accessToken = cookies.get('access_token')
    fetch(
      'https://dashboard-api.lemonclub.io/api/v1/resetResidentialProxyPassword',
      {
        method: 'POST',
        body: JSON.stringify({
          discord_token: accessToken,
          proxy_type: `${props.selectedPlan}`,
        }),
      }
    )
      .then(() => {
        successHandler('Password Changed Successfully!')
      })
      .catch((error) => {
        console.error('Error:', error)
        errorHandler('Error changing password, please try again later!')
      })
  }

  const AmountList = [{ label: 0 }, { label: 1 }, { label: 2 }]

  const [isDark, setIsDark] = useState(true)

  const toggleTheme = (selectedTheme) => {
    if (selectedTheme === 'light') {
      setIsDark(false)
    } else if (selectedTheme === 'dark') {
      setIsDark(true)
    }

    localStorage.setItem('theme', selectedTheme)
  }

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) {
      toggleTheme(savedTheme)
    }
  }, [isDark])

  useEffect(() => {
    if (props.selectedPlan === 'LemonPrime') {
      setPerformancePool(false)
    } else {
      setPerformancePool(undefined)
    }
  }, [props])

  return (
    <div className={`generate-container ${Styles.generate_container}`}>
      <div className={Styles.generate_brand}>
        {icon_items.generated_proxy}

        <span>Generate Proxies</span>
      </div>

      <div className={Styles.select}>
        <div className={Styles.label}>Region Pool</div>

        <select
          className={Styles.selectPlan}
          value={region}
          onChange={(e) => setRegion(e.target.value)}
        >
          {regionList.map((item) => (
            <option key={item.code} value={item.code}>
              {item.label}
            </option>
          ))}
        </select>

        <div className={Styles.label}>Plan Pool</div>

        <select
          className={Styles.selectPlan}
          value={proxySticky}
          onChange={(e) => setProxySticky(e.target.value)}
        >
          <option disabled value="">
            Choose Rotate or Sticky
          </option>
          <option value="true">Sticky</option>
          <option value="false">Rotate</option>
        </select>

        <div className={Styles.label}>Proxy Amount</div>

        <SearchInput
          background="var(--re-select-dark-bg)"
          option={AmountList}
          onSelectChange={(e) => setProxyCount(e.target.value)}
          value={proxyCount}
          type="number"
          width="100%"
        />

        {props.selectedPlan === 'LemonPrime' && (
          <>
            <div className={Styles.label}>Performance Pool</div>

            <Switch setChecked={setPerformancePool} checked={performancePool} />

            <div className={Styles.subLabel}>
              Performance Pool is enabled, giving you access to the fastest and
              cleanest IPs in the pool.
            </div>
          </>
        )}
      </div>

      <div className={Styles.option}>
        <AnimationButton
          width="calc((100% - 11px) / 2)"
          Styles={{ margin: '0px' }}
          height="42px"
          background="var(--re-data-left-bg)"
          color="var(--font-color)"
          size="16px"
          borderRadius="12px"
          button="special"
          label="Reset Password"
          handler={resetProxyPassword}
        />

        <AnimationButton
          width="calc((100% - 11px) / 2)"
          height="42px"
          background="var(--re-data-left-bg)"
          color="var(--font-color)"
          size="16px"
          borderRadius="12px"
          button="special"
          label="Reset"
          handler={resetHandler}
        />
      </div>
      <div className={Styles.option}>
        <AnimationButton
          width="100%"
          height="42px"
          background="linear-gradient(90deg, #474EEB 0%, #7177EC 100%), #212121"
          color="#FFF"
          size="16px"
          borderRadius="12px"
          button="light"
          label={!loadingGen ? 'Generate' : 'Generating...'}
          handler={generateProxies}
        />
      </div>

      <ProxyList proxyList={proxyList} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  selectedPlan: state.selectedPlan,
})

export default connect(mapStateToProps)(Generate)
