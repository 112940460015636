import React, { useState, useEffect } from 'react'
import Styles from './isp.module.scss'
import { icon_items } from '../../../pages/ResiDashboard/icon-item'
import Logo from '../../../assets/images/logo.svg'
import Growtopia from '../../../assets/images/lemon-topia.png'
import checkCircle from '../../../assets/images/signIn/check-circle.png'
import AnimationButton from '../../UI/AnimationButton'
import AnimationButtonGo from 'components/UI/AnimationButtonGo'

const ISP = (props) => {
  const { membershipType, cookies, user } = props
  const [loadingISP, setLoadingISP] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('')
  const [selectedFeature, setSelectedFeature] = useState([])
  const [proxyRegions, setProxyRegions] = useState({})
  const [proxyRegionsDC, setProxyRegionsDC] = useState({})
  const [regionList, setRegionLIst] = useState({})
  const [selectedRegion, setSelectedRegion] = useState('')
  const [amountList, setAmountList] = useState([])
  const [selectedAmount, setSelectedAmount] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [dateList, setDateList] = useState([])
  const [calculatedPrice, setCalculatedPrice] = useState('')
  const [fromPrice, setFromPrice] = useState('')
  const [pricePerProxy, setPricePerProxy] = useState('')
  const [proxyType, setProxyType] = useState('isp')
  const [ispStatus, setISPstatus] = useState('')
  const [dcStatus, setDcStatus] = useState('')
  const [PaymentMethod, setPaymentMethod] = useState('stripe')
  const [button1Clicked, setButton1Clicked] = useState(true)
  const [button2Clicked, setButton2Clicked] = useState(false)
  const [referral, setReferral] = useState(null)

  useEffect(() => {
    window.addEventListener('Rewardful.initialized', function () {
      setReferral(window.Rewardful.referral)
    })
  }, [])

  const proxyTypeList = {
    'AIO ISPs': 'isp',
    'Growtopia Socks': 'growtopia',
    'Sorrentos ISPs': 'dc',
  }

  const plans_features = {
    'Growtopia Socks': [
      'Speed 30ms-80ms',
      'SOCKS5/HTTP UDP',
      'All Green guaranteed on Subnet',
      'Bypass Proxies Discounted',
    ],
    'AIO ISPs': [
      'Speed 20ms-70ms',
      'Private Anti-Ban Solution',
      'All Sneakers & Tickets Website Supported',
      'Auto-Renewal',
    ],
    'Sorrentos ISPs': [
      'Speed of 40ms/70ms on Netcup',
      'Located in Frankfurt, 🇩🇪',
      'Support for almost all Sneakers Websites',
    ],
  }

  const plans_amounts = {
    'Growtopia Socks': ['10', '25', '50', '100', '255'],
    'AIO ISPs': ['1', '3', '5', '10', '25', '50', '100', '255'],
    'Sorrentos ISPs': ['10', '25', '50', '100', '255'],
  }

  const plans_date = {
    'Growtopia Socks': [{ label: '30 day', value: '30' }],
    'AIO ISPs': [
      { label: '1 day', value: '1' },
      { label: '30 day', value: '30' },
      { label: '90 day', value: '90' },
    ],

    'Sorrentos ISPs': [{ label: '30 day', value: '30' }],
  }

  const price_growtopia = {
    10: { 30: 22 },
    25: { 30: 50 },
    50: { 30: 95 },
    100: { 30: 160 },
    255: { 30: 280.5 },
  }

  const price_aio = {
    free: {
      1: { 30: 4.0, 90: 9.0, 1: 5.0 },
      3: { 30: 7.0, 90: 18.0, 1: 7.0 },
      5: { 30: 15.0, 90: 37, 1: 5.0 },
      10: { 30: 25.0, 90: 46.0, 1: 5.0 },
      25: { 30: 60.0, 90: 114.0, 1: 12.0 },
      50: { 30: 115.0, 90: 228.0, 1: 25.0 },
      100: { 30: 219.0, 90: 456.0, 1: 50.0 },
      255: { 30: 370.0, 90: 1110.0, 1: 125.0 },
    },
    paid: {
      1: { 30: 3.0, 90: 9.0, 1: 5.0 },
      3: { 30: 7.0, 90: 18.0, 1: 7.0 },
      5: { 30: 12.0, 90: 37, 1: 5.0 },
      10: { 30: 19.0, 90: 46.0, 1: 5.0 },
      25: { 30: 48.0, 90: 114.0, 1: 12.0 },
      50: { 30: 95.0, 90: 228.0, 1: 25.0 },
      100: { 30: 190.0, 90: 456.0, 1: 50.0 },
      255: { 30: 370.0, 90: 1110.0, 1: 125.0 },
    },
  }

  const price_santo = {
    free: {
      1: { 30: 3.0, 90: 9.0, 1: 5.0 },
      5: { 30: 12.0, 90: 37, 1: 5.0 },
      10: { 30: 17.99, 90: 46.0, 1: 5.0 },
      25: { 30: 38.99, 90: 114.0, 1: 12.0 },
      50: { 30: 72.99, 90: 228.0, 1: 25.0 },
      100: { 30: 139.99, 90: 456.0, 1: 50.0 },
      255: { 30: 330.0, 90: 1110.0, 1: 125.0 },
    },
    paid: {
      1: { 30: 3.0, 90: 9.0, 1: 5.0 },
      5: { 30: 12.0, 90: 37, 1: 5.0 },
      10: { 30: 17.99, 90: 46.0, 1: 5.0 },
      25: { 30: 38.99, 90: 114.0, 1: 12.0 },
      50: { 30: 72.99, 90: 228.0, 1: 25.0 },
      100: { 30: 139.99, 90: 456.0, 1: 50.0 },
      255: { 30: 330.0, 90: 1110.0, 1: 125.0 },
    },
  }

  const price_aio_from = {
    free: 1.45,
    paid: 1.45,
  }

  const price_surrento_from = {
    free: 1.29,
    paid: 1.29,
  }

  useEffect(() => {
    fetch('https://dashboard-api.lemonclub.io/api/v1/fetchStaticProxyRegions')
      .then((response) => response.json())
      .then((data) => {
        setProxyRegions(data)

        setInitialPlan(data)
      })
      .catch((error) => {
        console.error('Error fetching proxy regions:', error)
      })
  }, [])

  useEffect(() => {
    fetch('https://dashboard-api.lemonclub.io/api/v1/fetchStaticProxyRegionsDC')
      .then((response) => response.json())
      .then((data) => {
        setProxyRegionsDC(data)
      })
      .catch((error) => {
        console.error('Error fetching proxy DcRegions:', error)
      })
  }, [])

  useEffect(() => {
    calculatePrice(selectedPlan, selectedAmount, selectedDate, membershipType)
    calculatePerProxy(
      selectedPlan,
      selectedAmount,
      selectedDate,
      membershipType
    )
  }, [selectedPlan, selectedAmount, selectedDate, membershipType])

  const setProxyStatus = (proxyType, stockData) => {
    const setStatus = (status) => {
      switch (proxyType) {
        case 'isp':
          setISPstatus(status)
          break
        case 'dc':
          setDcStatus(status)
          break
        default:
        // Handle unknown proxy types
      }
    }

    setStatus(stockData[proxyType] === 'instock')
  }

  const checkAllProxyStocks = () => {
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/checkStock', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: accessToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const proxyTypes = ['isp', 'dc']
        proxyTypes.forEach((proxyType) => {
          setProxyStatus(proxyType, data)
        })

        props.setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        alert('Error checking stock, please try again.')
      })
  }

  const setInitialPlan = (data) => {
    const plan = document.getElementById('plan_static').value
    setSelectedPlan(plan)
    setSelectedFeature(plans_features[plan])
    setAmountList(plans_amounts[plan])
    setRegionLIst(data)
    setDateList(plans_date[plan])
    setCalculatedPrice('0')
  }

  useEffect(() => {
    const fromPrice = price_aio_from[membershipType]
    setFromPrice(fromPrice)
  }, [membershipType])

  useEffect(() => {
    checkAllProxyStocks()
  }, [])

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value
    setSelectedPlan(selectedValue)
    setSelectedFeature(plans_features[selectedValue])
    setSelectedRegion('')
    if (selectedValue === 'AIO ISPs') {
      setRegionLIst(proxyRegions)
    } else if (selectedValue === 'Sorrentos ISPs') {
      setRegionLIst(proxyRegionsDC)
    } else {
      setRegionLIst({ DE: 'Germany', US: 'United States' })
    }
    //setRegionLIst(selectedValue === "AIO ISPs" ? proxyRegions : proxyRegionsDC);
    setSelectedAmount('')
    setAmountList(plans_amounts[selectedValue])
    setSelectedDate('')
    setDateList(plans_date[selectedValue])
    setFromPrice(
      selectedValue === 'AIO ISPs'
        ? price_aio_from[membershipType]
        : price_surrento_from[membershipType]
    )
    setProxyType(proxyTypeList[selectedValue])
    setCalculatedPrice('0')
  }
  const handleSelectRegion = (event) => {
    const selectedValue = event.target.value
    setSelectedRegion(selectedValue)
  }

  const handleSelectedAmount = (event) => {
    const selectedAmount = event.target.value
    setSelectedAmount(selectedAmount)
  }

  const handleSelectedDate = (event) => {
    const selectedDate = event.target.value
    setSelectedDate(selectedDate)
  }

  const calculatePrice = (plan, amount, date, membership) => {
    if (plan !== '' && amount !== '' && date !== '' && membership !== '') {
      let calculatedPrice = ''
      if (plan === 'AIO ISPs') {
        calculatedPrice = price_aio[membership][amount][date]
      } else if (plan === 'Growtopia Socks') {
        calculatedPrice = price_growtopia[amount][date]
      } else {
        calculatedPrice = price_santo[membership][amount][date]
      }
      setCalculatedPrice(calculatedPrice)
    }
  }

  const calculatePerProxy = (plan, amount, date, membership) => {
    if (plan !== '' && amount !== '' && date !== '') {
      let calculatedPrice = ''
      if (plan === 'AIO ISPs') {
        calculatedPrice = price_aio[membership][amount][date]
      } else if (plan === 'Growtopia Socks') {
        calculatedPrice = price_growtopia[amount][date]
      } else {
        calculatedPrice = price_santo[membership][amount][date]
      }
      setPricePerProxy((calculatedPrice / amount).toFixed(2))
    }
  }

  const handleISPPurchase = () => {
    if (selectedRegion === '') {
      alert('Please select Region')
      return
    }

    if (selectedAmount === '') {
      alert('Please select Amount')
      return
    }

    if (selectedDate === '') {
      alert('Please select Date')
      return
    }

    setLoadingISP(true)
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/createStaticOrder', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: `${accessToken}`,
        proxy_type: `${proxyType}`,
        proxy_amount: `${selectedAmount}`,
        proxy_region: `${selectedRegion}`,
        proxy_time: `${selectedDate}`,
        membership_type: `${membershipType}`,
        payment_method: PaymentMethod,
        referral: referral,
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setLoadingISP(false)
        window.location.href = `${data['purchase_url']}`
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleBilling = () => {
    const accessToken = cookies.get('access_token')
    fetch(
      'https://dashboard-api.lemonclub.io/api/v1/createCustomerPortalSession',
      {
        method: 'POST',
        body: JSON.stringify({
          discord_token: `${accessToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data['portal_session_url']
      })
  }

  const handleButton1Click = () => {
    setPaymentMethod('stripe')
    setButton1Clicked(true)
    setButton2Clicked(false)
  }

  const handleButton2Click = () => {
    setPaymentMethod('crypto')
    setButton1Clicked(false)
    setButton2Clicked(true)
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.brand}>
        {icon_items.purchase}
        <span>Purchase Plans</span>
      </div>

      <select
        id={'plan_static'}
        className={Styles.selectPlan}
        onChange={(event) => {
          handleSelectChange(event)
        }}
      >
        <option value={'AIO ISPs'}>AIO ISPs</option>
        <option value={'Sorrentos ISPs'}>Sorrentos ISPs</option>
        <option value={'Growtopia Socks'}>Growtopia Socks</option>
      </select>

      <div className={Styles.lab}>
        <span>{selectedPlan}</span>

        <div className={Styles.labList}>
          {selectedFeature.map((item, index) => (
            <div className={Styles.labItem} key={index}>
              <img src={checkCircle} alt="check-circle" />
              <div className={`main-text ${Styles.moreItemText}`}>{item}</div>
            </div>
          ))}
        </div>

        <div className={Styles.from}>As low as</div>

        <div className={Styles.labInfo}>
          <span>${fromPrice}/ISP</span>

          <AnimationButtonGo
            width="142px"
            height="32px"
            background="var(--re-white-button-bg)"
            color="var(--re-white-button-color)"
            size="13px"
            borderRadius="10px"
            button="dark"
            label="Learn More"
            goTo="https://discord.gg/lemonclub"
            isNewTab="_blank"
          />
        </div>

        <img
          className={Styles.logo}
          src={selectedPlan === 'Growtopia Socks' ? Growtopia : Logo}
        />
      </div>

      <div className={Styles.amount}>
        <div className={Styles.title}>
          {icon_items.data}

          <span>Data Amount</span>
        </div>

        <select
          className={Styles.selectPlan}
          value={selectedRegion}
          onChange={(event) => {
            handleSelectRegion(event)
          }}
        >
          <option disabled hidden value="">
            Region
          </option>
          {Object.entries(regionList).map(([key, value]) => (
            <option value={key} key={key}>
              {value}
            </option>
          ))}
        </select>

        <select
          id={'amount'}
          className={Styles.selectPlan}
          value={selectedAmount}
          onChange={(event) => {
            handleSelectedAmount(event)
          }}
        >
          <option disabled hidden value="">
            Amount
          </option>
          {amountList.map((item) => (
            <option value={item} key={item}>
              {item} ISPs
            </option>
          ))}
        </select>

        <select
          id={'date'}
          className={Styles.selectPlan}
          value={selectedDate}
          onChange={(event) => {
            handleSelectedDate(event)
          }}
        >
          <option disabled hidden value="">
            Length
          </option>
          {dateList.map((item) => (
            <option disabled={!ispStatus} value={item.value} key={item}>
              {item.label}
            </option>
          ))}
        </select>

        <div className={Styles.paymentLabel}>
          <span>Payment Method</span>
        </div>
        <div className={Styles.paymentMethod}>
          <button
            onClick={handleButton1Click}
            className={
              button1Clicked ? Styles.buttonClicked : Styles.buttonNotClicked
            }
          >
            <span>Stripe</span>
          </button>
          <button
            onClick={handleButton2Click}
            className={
              button2Clicked ? Styles.buttonClicked : Styles.buttonNotClicked
            }
          >
            <span>Crypto</span>
          </button>
        </div>

        <div className={Styles.dataLabel}>
          <span>Total</span>

          <span>Price per Proxy</span>
        </div>

        <div className={Styles.price}>
          <span>${calculatedPrice}</span>

          <span>${pricePerProxy}</span>
        </div>

        <div className={Styles.button}>
          <AnimationButton
            width="calc((100% - 11px) / 2)"
            height="42px"
            background="var(--re-white-yellow-bg)"
            color="#FFF"
            size="16px"
            borderRadius="12px"
            button="yellow"
            label={loadingISP ? 'Loading...' : 'Purchase'}
            handler={handleISPPurchase}
          />

          <AnimationButton
            width="calc((100% - 11px) / 2)"
            height="42px"
            background="var(--re-black-yellow-bg)"
            color="var(--font-color)"
            size="16px"
            borderRadius="12px"
            button="special"
            label="Manage Renewal"
            handler={handleBilling}
          />
        </div>
      </div>
    </div>
  )
}

export default ISP
