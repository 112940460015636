import React from 'react'
import Styles from './order.module.scss'
import { icon_items } from '../../../pages/ResiDashboard/icon-item'
import ProductList from '../hybrid-products'
import SearchInput from 'components/UI/SearchInput'
import AnimationButton from 'components/UI/AnimationButton'
import { saveAs } from 'file-saver'

const moment = require('moment')

const Order = (props) => {
  const { cookies } = props

  const [selectedStart, setSelectedStart] = React.useState('')
  const [selectedEnd, setSelectedEnd] = React.useState('')
  const [selectedPlan, setSelectedPlan] = React.useState('')

  const [responseData, setResponseData] = React.useState([])

  const handleSelectStart = (event) => {
    console.log(event.target.value)
    setSelectedStart(event.target.value)
  }

  const handleSelectEnd = (event) => {
    console.log(event.target.value)
    setSelectedEnd(event.target.value)
  }

  const formatSize = (d) => {
    if (!d) return `${(0).toFixed(2)} GB`
    d /= 1073741824
    if (Math.abs(d) < 0.5) {
      return `${(d * 1024).toFixed(2)} MB`
    } else if (Math.abs(d) > 1024) {
      return `${(d / 1024).toFixed(2)} TB`
    }
    return `${d.toFixed(2)} GB`
  }

  const getUserDCOrders = () => {
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/viewLemonLiveOrders', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: `${accessToken}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          data.sort((a, b) => {
            const dateA = moment(a.date_created, 'HH:mm DD/MM/YYYY')
            const dateB = moment(b.date_created, 'HH:mm DD/MM/YYYY')
            return dateB - dateA
          })
          setResponseData(data)
        }
        // props.setIsLoading(false);
      })
  }

  const handleDownloadAnalyticsOverall = async () => {
    if (selectedPlan === '') {
      alert('Please select order')
      return
    }

    if (selectedStart === '') {
      alert('Please select start date')
      return
    }

    if (selectedEnd === '') {
      alert('Please select end date')
      return
    }

    const accessToken = cookies.get('access_token')
    const response = await fetch(
      'https://dashboard-api.lemonclub.io/api/v1/fetchLemonLiveAnalyticsOverall',
      {
        method: 'POST',
        body: JSON.stringify({
          order_id: `${selectedPlan}`,
          discord_token: `${accessToken}`,
          start: `${selectedStart}`,
          end: `${selectedEnd}`,
          offset: 0,
          limit: 1000,
        }),
      }
    )

    if (!response.ok) {
      alert('Failed to fetch data')
      return
    }

    const data = await response.json()

    if (!Array.isArray(data)) {
      alert('Invalid data format')
      return
    }

    const csvRows = [
      'timestamp,requests,data',
      ...data.map(
        (row) => `${row.timestamp},${row.requests},${formatSize(row.data)}`
      ),
    ]

    const csvContent = csvRows.join('\n')
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, 'analytics.csv')
  }

  const handleDownloadAnalyticsDomains = async () => {
    if (selectedPlan === '') {
      alert('Please select order')
      return
    }

    if (selectedStart === '') {
      alert('Please select start date')
      return
    }

    if (selectedEnd === '') {
      alert('Please select end date')
      return
    }

    const accessToken = cookies.get('access_token')
    let allData = []
    let offset = 0
    const limit = 1000

    try {
      while (true) {
        const response = await fetch(
          'https://dashboard-api.lemonclub.io/api/v1/fetchLemonLiveAnalyticsDomains',
          {
            method: 'POST',
            body: JSON.stringify({
              order_id: `${selectedPlan}`,
              discord_token: `${accessToken}`,
              start: `${selectedStart}`,
              end: `${selectedEnd}`,
              offset,
              limit,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        if (!response.ok) {
          alert('Failed to fetch data')
          return
        }

        const data = await response.json()

        if (!Array.isArray(data)) {
          alert('Invalid data format')
          return
        }

        allData = allData.concat(data)

        if (data.length < limit) {
          break
        }

        offset += limit
      }

      const csvRows = [
        'hostname,requests,data',
        ...allData.map(
          (row) => `${row.hostname},${row.requests},${formatSize(row.data)}`
        ),
      ]

      const csvContent = csvRows.join('\n')
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      saveAs(blob, 'analytics.csv')
    } catch (error) {
      console.error('Error fetching data:', error)
      alert('An error occurred while fetching data')
    }
  }

  React.useEffect(() => {
    getUserDCOrders()
  }, [])

  return (
    <div className={`order-container ${Styles.order_container}`}>
      <div className={Styles.analytics}>
        <div className={Styles.bland}>
          <div className={Styles.bland_icon}>
            {icon_items.analytics}

            <span className={Styles.brand_title}> Analytics</span>
          </div>

          <div className={Styles.brand_description}>
            Here you can view proxy analytics.
          </div>
        </div>
        <div className={Styles.data_range}>
          <div className={Styles.option}>
            <div className={Styles.label}>From</div>
            <input
              type="date"
              onChange={handleSelectStart}
              max={new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className={Styles.option}>
            <div className={Styles.label}>To</div>
            <input type="date" onChange={handleSelectEnd} />
          </div>
          <div className={Styles.option}>
            <div className={Styles.label}>Order</div>
            <select
              onChange={(event) => setSelectedPlan(event.target.value)}
              className={Styles.select}
              style={{ backgroundColor: 'var(--re-select-dark-bg)' }}
            >
              <option>Choose Order</option>
              {responseData.map((item) => (
                <option key={item.order_id} value={item.order_id}>
                  {item.order_id}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={Styles.footer}>
          <AnimationButton
            width="182px"
            height="32px"
            background="linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%)"
            color="#FFF"
            size="13px"
            borderRadius="10px"
            button="yellow"
            label="Download Domains"
            handler={handleDownloadAnalyticsDomains}
          />
          <AnimationButton
            width="182px"
            height="32px"
            background="linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%)"
            color="#FFF"
            size="13px"
            borderRadius="10px"
            button="yellow"
            label="Download Overall"
            handler={handleDownloadAnalyticsOverall}
          />
        </div>
      </div>

      <div className={Styles.plan} style={{ marginTop: '15px' }}>
        <div className={Styles.bland}>
          <div className={Styles.bland_icon}>
            {icon_items.analytics}

            <span className={Styles.brand_title}> Your Orders</span>
          </div>

          <div className={Styles.brand_description}>
            Here you can see all of your static proxy orders.
          </div>
        </div>
        <div className={Styles.search}>
          <span className={Styles.brand_label}>Search</span>
          <SearchInput background="var(--re-select-dark-bg)" />
        </div>
      </div>

      <ProductList cookies={cookies} setIsLoading={props.setIsLoading} />
    </div>
  )
}

export default Order
