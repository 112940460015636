export const planPrices = {
  US: {
    200: {
      45: {
        5: '60',
      },
      60: {
        5: '75',
      },
    },
    300: {
      45: {
        10: '90',
      },
      60: {
        10: '105',
      },
    },
    500: {
      45: {
        20: '130',
      },
      60: {
        20: '145',
      },
    },
  },
  USELITE: {
    200: {
      45: {
        5: '60',
      },
      60: {
        5: '75',
      },
    },
    300: {
      45: {
        10: '90',
      },
      60: {
        10: '105',
      },
    },
    500: {
      45: {
        20: '130',
      },
      60: {
        20: '145',
      },
    },
  },
  JP: {
    200: {
      45: {
        5: '60',
      },
      60: {
        5: '75',
      },
    },
    300: {
      45: {
        10: '90',
      },
      60: {
        10: '105',
      },
    },
    500: {
      45: {
        20: '130',
      },
      60: {
        20: '145',
      },
    },
  },
  GB: {
    200: {
      45: {
        5: '60',
      },
      60: {
        5: '75',
      },
    },
    300: {
      45: {
        10: '90',
      },
      60: {
        10: '105',
      },
    },
    500: {
      45: {
        20: '130',
      },
      60: {
        20: '145',
      },
    },
  },
  DE: {
    200: {
      45: {
        5: '60',
      },
      60: {
        5: '75',
      },
    },
    300: {
      45: {
        10: '90',
      },
      60: {
        10: '105',
      },
    },
    500: {
      45: {
        20: '130',
      },
      60: {
        20: '145',
      },
    },
  },
}
