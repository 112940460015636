import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Login.module.scss'
import { icon_items } from './icon-item'
import logo from '../../assets/images/signIn/logo.svg'
import checkCircle from '../../assets/images/signIn/check-circle.png'
import discord from '../../assets/images/signIn/discord.svg'
import lightMainImage from '../../assets/images/signIn/light/full-light-main-image.png'
import mainImage from '../../assets/images/signIn/full-main-image.png'
import AnimationButton from '../../components/UI/AnimationButton'
import { ref } from 'yup'

const Login = (props) => {
  const { cookies } = props

  const [isDark, setIsDark] = useState(true)
  const [referral, setReferral] = useState(null)

  useEffect(() => {
    window.addEventListener('Rewardful.initialized', function () {
      setReferral(window.Rewardful.referral)
    })
  }, [])

  const moreItems = [
    'Private Residential Proxy',
    'All Public Proxy Providers at Retail.',
    'ISP Proxy for all usages Case.',
  ]

  const toggleTheme = (selectedTheme) => {
    if (selectedTheme === 'light') {
      document.documentElement.setAttribute('data-theme', 'light-theme')
      setIsDark(false)
    } else if (selectedTheme === 'dark') {
      document.documentElement.removeAttribute('data-theme')
      setIsDark(true)
    }

    localStorage.setItem('theme', selectedTheme)
  }

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) {
      toggleTheme(savedTheme)
    }
  }, [])

  useEffect(() => {
    const fragment = new URLSearchParams(window.location.hash.slice(1))
    const [accessToken, tokenType] = [
      fragment.get('access_token'),
      fragment.get('token_type'),
    ]

    if (accessToken) fetchLogin(accessToken, tokenType)
  }, [])

  const fetchLogin = (accessToken, tokenType) => {
    fetch('https://discord.com/api/users/@me', {
      method: 'GET',
      headers: {
        authorization: `${tokenType} ${accessToken}`,
      },
    })
      .then((result) => result.json())
      .then((response) => {
        if (!response.username) {
          console.error('Error:', response.message)
        }
        cookies.set('access_token', accessToken, { path: '/', maxAge: 604800 })
        cookies.set('token_type', tokenType, { path: '/', maxAge: 604800 })

        const redirectInterval = setInterval(() => {
          clearInterval(redirectInterval)
          window.location.href = '/resi-dashboard'
        }, 0)
      })
      .catch(console.error)
  }

  const goToResiDashboard = () => {
    const fragment = new URLSearchParams(window.location.hash.slice(1))
    const [accessToken, tokenType] = [
      fragment.get('access_token'),
      fragment.get('token_type'),
    ]

    const isLoggedIn = cookies.get('access_token') && cookies.get('token_type')
    if (isLoggedIn) {
      // User is already logged in, redirect to the dashboard after 2 seconds
      const redirectInterval = setInterval(() => {
        clearInterval(redirectInterval)
        window.location.href = '/resi-dashboard'
      }, 500)
      return
    }

    if (!accessToken) {
      // Redirect the user to the authorization page if the access token is missing
      window.location.href =
        'https://discord.com/api/oauth2/authorize?client_id=1117499513792385216&response_type=token&redirect_uri=https%3A%2F%2Fnew-dashboard.lemonclub.io&scope=identify+email'
      return
    }

    fetchLogin(accessToken, tokenType)
  }

  useEffect(() => {
    const fragment = new URLSearchParams(window.location.search)
    const ref = fragment.get('via')

    if (ref) {
      const script = document.createElement('script')
      script.src = 'https://r.wdfl.co/rw.js'
      script.async = true
      script.setAttribute('data-rewardful', '226b12')
      document.head.appendChild(script)
      document.head.removeChild(script)
    }
  })

  return (
    <div className={styles.signIn}>
      <div className={styles.signInContainer}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>

        <div className={styles.moreAbout}>
          <div className={styles.moreIcon}>{icon_items.moreAbout}</div>
          <div className={styles.moreTitle}>More about LemonClub</div>
          <div className={styles.moreItems}>
            {moreItems.map((item, index) => (
              <div className={styles.moreItem} key={index}>
                <img src={checkCircle} alt="check-circle" />
                <span className={`main-text ${styles.moreItemText}`}>
                  {item}
                </span>
              </div>
            ))}
          </div>
          <div className="solution">
            <p className="solution-heading">Looking for B2B solutions?</p>
            <p className="solution-content">
              We got you covered, discover ProxyVenture!
            </p>
            <AnimationButton
              width="173px"
              height="42px"
              background="var(--re-white-yellow-bg)"
              color="#FFF"
              size="16px"
              borderRadius="52px"
              button="yellow"
              label="Contact us"
              href="https://www.proxyventure.io/"
            />
          </div>
        </div>

        <div className={styles.main}>
          <div className={styles.mainContainer}>
            <div className={styles.textLogoBigContainer}>
              <div className={styles.textLogoBig}>{icon_items.textLogo}</div>
            </div>

            <div className={styles.signInLabel}>
              <NavLink className={styles.signInLabel}>LOGIN</NavLink>
            </div>

            <div className={styles.signInHeading}>Login to LemonClub</div>
            <div className="main-text">Connect your discord to continue</div>
            <div className={styles.connectBox}>
              <div className={styles.connectContent}>
                <img src={discord} alt="discord" />
                <span className={styles.connectLabel}>Connect Discord</span>
              </div>

              <AnimationButton
                width="173px"
                height="42px"
                background="var(--re-white-yellow-bg)"
                color="#FFF"
                size="16px"
                borderRadius="52px"
                button="yellow"
                label="Connect"
                handler={goToResiDashboard}
              />
            </div>
            <div className="main-text" style={{ fontSize: '15px' }}>
              Experiencing troubles? Contact us <span>here</span>
            </div>
            <div className={styles.mainImage}>
              <img src={isDark ? mainImage : lightMainImage} alt="main" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
