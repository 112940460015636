import React, { useState, useRef, useEffect } from 'react'
import Styles from './ResiDashboard.module.scss'
import Purchase from '../../components/Layouts/purchase'
import Index from 'components/Layouts/analytics'
import Generate from '../../components/Layouts/generate'
import Loading from 'pages/Loading'

const ResiDashboard = (props) => {
  const { cookies } = props
  const [user, setUser] = useState({})
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [discordEmail, setDiscordEmail] = useState('')
  const [membershipType, setMembershipType] = useState('')

  //setUser
  useEffect(() => {
    // Check if the access token is already stored in local storage
    const accessToken = cookies.get('access_token')
    const tokenType = cookies.get('token_type')

    if (!accessToken) {
      // If it's not, redirect to the root of the app
      setIsLoggedIn(false)
      return
    }
    // If it is, use it to make the API call
    fetch('https://discord.com/api/users/@me', {
      method: 'GET',
      headers: {
        authorization: `${tokenType} ${accessToken}`,
      },
    })
      .then((result) => {
        if (result.ok) {
          return result.json()
        }
        cookies.remove('access_token')
        cookies.remove('token_type')
        window.location.replace('/')
        alert('Unauthorized access! Please log in.')
        throw new Error(`Request failed with status: ${result.status}`)
      })
      .then((response) => {
        setUser(response)
        setDiscordEmail(response.email)
      })
      .catch(console.error)
  }, [])

  //setMembershipType
  useEffect(() => {
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/fetchMembershipStatus', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: `${accessToken}`,
      }),
    })
      .then((response) => {
        return response.text()
      })
      .then((data) => {
        setMembershipType(data)
      })
  }, [])

  useEffect(() => {
    const fragment = new URLSearchParams(window.location.hash.slice(1))
    const [accessToken, tokenType] = [
      fragment.get('access_token'),
      fragment.get('token_type'),
    ]

    if (!accessToken) {
      setIsLoggedIn(false)
      return
    }

    fetch('https://discord.com/api/users/@me', {
      method: 'GET',
      headers: {
        authorization: `${tokenType} ${accessToken}`,
      },
    })
      .then((result) => result.json())
      .then((response) => {
        if (!response.username) {
          setIsLoggedIn(false)
          console.error('Error:', response.message)
        }
        setIsLoggedIn(true)
        cookies.set('access_token', accessToken, {
          path: '/',
          maxAge: '604800',
        })
        cookies.set('token_type', tokenType, { path: '/', maxAge: '604800' })
        const { username, discriminator, email } = response
      })
      .then(() => {
        window.location = '/resi-dashboard'
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    const accessToken = cookies.get('access_token')
    const tokenType = cookies.get('token_type')
    if (!accessToken) {
      window.location.href =
        'https://discord.com/api/oauth2/authorize?client_id=1117499513792385216&response_type=token&redirect_uri=https%3A%2F%2Fnew-dashboard.lemonclub.io&scope=identify+email'
    } else if (discordEmail && membershipType) {
      fetch('https://dashboard-api.lemonclub.io/api/v1/checkExistingCustomer', {
        method: 'POST',
        body: JSON.stringify({
          discord_token: `${accessToken}`,
          discord_email: `${discordEmail}`,
          membership_type: `${membershipType}`,
        }),
      })
    }
  }, [discordEmail, membershipType])

  //Mine
  const tagList = ['Purchase', 'Plan & Proxies', 'Generate']
  const [isLoading, setIsLoading] = useState(true)

  const [selectedTab, setSelectedTab] = useState(null)

  const changeTab = (selectedTab) => {
    if (selectedTab === 'Purchase') {
      document.getElementById('tab0').style.display = 'block'
      document.getElementById('tab1').style.display = 'none'
      document.getElementById('tab2').style.display = 'none'
    } else if (selectedTab === 'Plan & Proxies') {
      document.getElementById('tab0').style.display = 'none'
      document.getElementById('tab1').style.display = 'block'
      document.getElementById('tab2').style.display = 'none'
    } else if (selectedTab === 'Generate') {
      document.getElementById('tab0').style.display = 'none'
      document.getElementById('tab1').style.display = 'none'
      document.getElementById('tab2').style.display = 'block'
    }

    setSelectedTab(selectedTab)
  }

  function myFunction() {
    const tab0 = document.getElementById('tab0')
    const tab1 = document.getElementById('tab1')
    const tab2 = document.getElementById('tab2')

    if (tab0 && tab1 && tab2) {
      tab0.style.display = 'block'
      tab1.style.display = 'block'
      tab2.style.display = 'block'
    } else {
      console.log("there aren't these tabs.")
    }
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 781px)')

    function handleResize(e) {
      if (e.matches) {
        myFunction()
      }
    }

    mediaQuery.addEventListener('change', handleResize)

    if (mediaQuery.matches) {
      myFunction()
    }

    return () => {
      mediaQuery.removeEventListener('change', handleResize)
    }
  }, [])
  //mine

  return (
    <div className={Styles.residential_container}>
      <div className={Styles.residential}>
        <span className={Styles.title}>Residential Proxies</span>

        <div className={Styles.tab}>
          {tagList.map((item, index) => (
            <button
              onClick={() => changeTab(item)}
              key={index}
              className={selectedTab === item ? Styles.active : ''}
            >
              {item}
            </button>
          ))}
        </div>

        <div className={Styles.container}>
          <div id="tab0" className={Styles.purchase}>
            <Purchase
              membershipType={membershipType}
              user={user}
              cookies={cookies}
              setIsLoading={setIsLoading}
            />
          </div>

          <div id="tab1" className={Styles.analytics}>
            <Index
              membershipType={membershipType}
              user={user}
              cookies={cookies}
            />
          </div>

          <div id="tab2" className={Styles.generate}>
            <Generate
              membershipType={membershipType}
              user={user}
              cookies={cookies}
            />
          </div>
        </div>
        {isLoading ? <Loading /> : ''}
      </div>
    </div>
  )
}

export default ResiDashboard
