import React, { useState, useEffect } from 'react'
import Styles from './productList.module.scss'
import ModalTopUp from 'components/UI/ModalTopUp'
import Modal from 'components/UI/Modal'
import Cstyles from '../DetailModal/DetailModal.module.scss'
import Zstyles from 'components/UI/ModalTopUp/ModalTopUp.module.scss'
import ContentStyles from '../DetailModal/ModalContent/modalContent.module.scss'
import AnimationButton from 'components/UI/AnimationButton'
import ListStyles from '../modalContentList/modalContentList.module.scss'
import { errorHandler, successHandler } from 'helpers'
import { icon_items } from 'pages/ResiDashboard/icon-item'
import Copy from '../../../assets/images/copy.svg'
import SelectInput from '../../UI/SelectInput'

const ProductList = (props) => {
  const { cookies } = props
  const [sideDrawer, setSideDrawer] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [responseData, setResponseData] = useState([])
  const [modalData, setModalData] = useState([])
  const [selectedAmount, setSelectedAmount] = useState('')
  const [PaymentMethod, setPaymentMethod] = useState('stripe')
  const [button1Clicked, setButton1Clicked] = useState(true)
  const [button2Clicked, setButton2Clicked] = useState(false)
  const [fromPrice, setFromPrice] = useState(0)
  const [priceResi, setPriceResi] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState('')
  const [itemPrice, setItemPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [referral, setReferral] = useState(null)

  useEffect(() => {
    window.addEventListener('Rewardful.initialized', function () {
      setReferral(window.Rewardful.referral)
    })
  }, [])

  const priceGB = {
    200: 12,
    300: 9,
    500: 6.5,
  }

  const handleDateValue = (event) => {
    setSelectedAmount(event.target.value)
    setTotalPrice(event.target.value * itemPrice)
  }

  const handleButton1Click = (event) => {
    setPaymentMethod('stripe')
    setButton1Clicked(true)
    setButton2Clicked(false)
  }

  const handleButton2Click = () => {
    setPaymentMethod('crypto')
    setButton1Clicked(false)
    setButton2Clicked(true)
  }

  const handleShowPopup = (order, ipAmount, bandwidth, expiry) => {
    setTotalPrice(0)
    setShowPopup(!showPopup)
    setSideDrawer(false)
    setSelectedOrder(order)
    setItemPrice(priceGB[ipAmount])
  }

  const handleCopyOne = (e) => {
    var imgElement = e.target
    var parentDiv = imgElement.parentNode.parentNode
    var spanElement = parentDiv.querySelector('span')
    const proxyText = spanElement.innerHTML
    navigator.clipboard.writeText(proxyText)
    successHandler('Successfully Copied!')
  }

  const handleCopyGen = () => {
    const proxyText = modalData.join('\n')
    navigator.clipboard.writeText(proxyText)
    successHandler('Successfully Copied!')
  }

  const handleDownloadGen = () => {
    const proxyText = modalData.join('\n')
    const blob = new Blob([proxyText], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'proxies.txt'
    link.click()
    URL.revokeObjectURL(url)
  }

  const moment = require('moment')

  const handleTopUpPurchase = () => {
    if (selectedAmount === '') {
      alert('Please select Amount')
      return
    }

    setLoading(true)

    return new Promise((resolve, reject) => {
      const accessToken = cookies.get('access_token')

      fetch('https://dashboard-api.lemonclub.io/api/v1/createLemonLiveTopUp', {
        method: 'POST',
        body: JSON.stringify({
          discord_token: accessToken,
          order_number: selectedOrder,
          gb_amount: selectedAmount,
          payment_method: PaymentMethod,
          referral: referral,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          window.location.href = data['purchase_url']
          resolve()
        })
        .catch((error) => {
          console.error(error)
          setLoading(false) // Set loading to false since the purchase failed
          reject()
        })
    })
  }

  const handleButtonReset = (orderId) => {
    const accessToken = cookies.get('access_token')
    fetch(
      'https://dashboard-api.lemonclub.io/api/v1/resetLemonLiveOrderPassword',
      {
        method: 'POST',
        body: JSON.stringify({
          discord_token: `${accessToken}`,
          order_id: `${orderId}`,
        }),
      }
    )
      .then(() => {
        successHandler('Password Changed Successfully!')
      })
      .catch((error) => {
        console.error('Error:', error)
        errorHandler('Error changing password, please try again later!')
      })
  }

  const getUserDCOrders = () => {
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/viewLemonLiveOrders', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: `${accessToken}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          data.sort((a, b) => {
            const dateA = moment(a.date_created, 'HH:mm DD/MM/YYYY')
            const dateB = moment(b.date_created, 'HH:mm DD/MM/YYYY')
            return dateB - dateA
          })
          setResponseData(data)
        }
        // props.setIsLoading(false);
      })
  }

  useEffect(() => {
    getUserDCOrders()
  }, [])

  const toggleModal = (e) => {
    setShowSettings(!showSettings)
    setSideDrawer(false)
  }

  const controlAction = (e, order_id, isView) => {
    setModalData([])
    const accessToken = cookies.get('access_token')
    e.preventDefault()
    fetch('https://dashboard-api.lemonclub.io/api/v1/viewLemonLiveIPList', {
      method: 'POST',
      body: JSON.stringify({
        order_id: order_id,
        discord_token: `${accessToken}`,
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        const dataArray = data.split('\n')
        setModalData(dataArray)

        setTimeout(() => {
          if (isView) {
            toggleModal()
          } else {
            const proxyText = data
            const blob = new Blob([proxyText], { type: 'text/plain' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = 'proxies.txt'
            link.click()
            URL.revokeObjectURL(url)
          }
        }, 100)
      })
      .catch(console.error)
  }

  const dataValues = [
    { label: 1 },
    { label: 2 },
    { label: 3 },
    { label: 4 },
    { label: 5 },
    { label: 10 },
    { label: 20 },
    { label: 50 },
    { label: 100 },
  ]

  return (
    <div
      className={`proxy-list ${Styles.proxy_container}`}
      style={{ overflow: 'scroll' }}
    >
      <div className={Styles.proxy_list}>
        <tr className={`${Styles.proxy} ${Styles.proxy_ref}`}>
          <th>Product</th>
          <th>Quantity</th>
          <th>Date</th>
          <th>Expiration</th>
          <th>Data Left</th>
          <th>Actions</th>
        </tr>
        <div className={Styles.desktop}>
          {responseData.map((data, index) => (
            <tr
              className={`${Styles.proxy} ${Styles.proxy_content}`}
              key={index}
            >
              <td>
                <span className={Styles.ProductName}>LemonLive</span>{' '}
                <span className={Styles.ProductType}>Hybrid</span>
              </td>
              <td>{data.quantity}</td>
              <td>{data.date_created}</td>
              <td>
                <div
                  className={
                    !data.isExpired ? Styles.freeDateLabel : Styles.expiredLabel
                  }
                >
                  {!data.isExpired ? `in ${data.time_left}` : 'Expired'}
                </div>
              </td>
              <td>
                <span
                  className={
                    data.data_left <= 0.2 * data.data_limit
                      ? Styles.LowData
                      : Styles.HighData
                  }
                >
                  {Number(data.data_left).toFixed(2)}
                </span>{' '}
                <span className={Styles.Data}>/ {data.data_limit} GB</span>
              </td>
              <td>
                <div className={Styles.buttons}>
                  <button
                    className={Styles.view}
                    onClick={(e) => controlAction(e, data.order_id, true)}
                  >
                    View
                  </button>
                  <button
                    className={Styles.reset}
                    onClick={() => handleButtonReset(data.order_id)}
                  >
                    Reset Password
                  </button>
                  <button
                    className={Styles.download}
                    onClick={(e) => controlAction(e, data.order_id, false)}
                  >
                    Download
                  </button>
                  <button
                    className={Styles.topUp}
                    onClick={(e) =>
                      handleShowPopup(
                        data.order_id,
                        data.quantity,
                        data.data_limit,
                        data.expiry_days
                      )
                    }
                  >
                    TopUp
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </div>

        {showPopup ? (
          <ModalTopUp show={showPopup} variant={Zstyles.modal}>
            <div className={Styles.amount}>
              <div className={Styles.title}>
                {icon_items.data}

                <span>Top-Up Data</span>
              </div>

              <SelectInput
                background="var(--hover-bg-color)"
                option={dataValues}
                onSelectChange={handleDateValue}
                isGB={true}
              />

              <div className={Styles.dataLabel}>
                <span>Total</span>

                <span>Price per GB</span>
              </div>

              <div className={Styles.price}>
                <span>${totalPrice}</span>

                <span>${itemPrice}</span>
              </div>

              <div className={Styles.button}>
                <AnimationButton
                  width="calc((100% - 11px) / 2)"
                  height="42px"
                  background="var(--re-white-yellow-bg)"
                  color="#FFF"
                  size="16px"
                  borderRadius="12px"
                  button="yellow"
                  label={!loading ? 'Purchase' : 'Loading...'}
                  handler={handleTopUpPurchase}
                />

                <AnimationButton
                  width="calc((100% - 11px) / 2)"
                  height="42px"
                  background="var(--re-black-yellow-bg)"
                  color="var(--font-color)"
                  size="16px"
                  borderRadius="12px"
                  button="special"
                  label="Close"
                  handler={(e) => handleShowPopup(e)}
                />
              </div>
            </div>
          </ModalTopUp>
        ) : null}

        {showSettings ? (
          <Modal show={showSettings} variant={Cstyles.modal}>
            <div
              className={Cstyles.container}
              style={{ width: '75%', margin: 'auto' }}
            >
              <div
                className={`analytics-container ${ContentStyles.analytics_container}`}
              >
                <div className={`${ListStyles.proxy_container}`}>
                  <div className={ListStyles.proxy_option}>
                    {icon_items.generated_proxy}

                    <span>Generated Proxies</span>

                    <AnimationButton
                      width="132px"
                      height="32px"
                      background="var(--re-white-button-bg)"
                      color="var(--re-white-button-color)"
                      size="13px"
                      borderRadius="10px"
                      button="dark"
                      label="Copy All"
                      handler={handleCopyGen}
                    />

                    <AnimationButton
                      width="142px"
                      height="32px"
                      background="linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%)"
                      color="#FFF"
                      size="13px"
                      borderRadius="10px"
                      button="yellow"
                      label="Download"
                      handler={handleDownloadGen}
                    />
                  </div>

                  <div className={ListStyles.proxy_list}>
                    {modalData.map((item, index) => (
                      <div className={ListStyles.proxy} key={index}>
                        <span>{item}</span>

                        <div className={ListStyles.copy_icon}>
                          <img src={Copy} alt="copy" onClick={handleCopyOne} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={ContentStyles.button}>
                  <AnimationButton
                    width="132px"
                    height="42px"
                    background="var(--re-black-yellow-bg)"
                    color="var(--font-color)"
                    size="16px"
                    borderRadius="12px"
                    button="special"
                    label="Close"
                    handler={(e) => toggleModal(e)}
                  />
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        <div className={Styles.mobile}>
          {responseData.map((data, index) => (
            <div className={Styles.productCardContainer} key={index}>
              <div className={Styles.productCard}>
                <div className={Styles.product_head}>
                  <span className={Styles.product_name}>
                    <span className={Styles.product_headLabel}>
                      {data.product_head}{' '}
                    </span>{' '}
                    {data.product_name}
                  </span>
                  <span className={`${Styles.quantity} ${Styles.right}`}>
                    <span className={Styles.quantity_No}>{data.quantity}</span>{' '}
                    ISPs
                  </span>
                </div>

                <div className={`${Styles.product_head} ${Styles.day}`}>
                  <span className={Styles.day_label}>Date</span>
                  <span className={Styles.white}>{data.date_created}</span>
                  <span className={Styles.expired_label}>Expiration Date</span>
                  <div className={`${Styles.right} ${Styles.white}`}>
                    <div
                      className={`${
                        !data.isExpired
                          ? Styles.freeDateLabel
                          : Styles.expiredLabel
                      } ${Styles.marginRight}`}
                    >
                      {!data.isExpired ? `in ${data.time_left}` : 'Expired'}
                    </div>
                    {data.date_expiry}
                  </div>
                </div>

                <div className={`${Styles.product_head} ${Styles.location}`}>
                  <span className={Styles.day_label}>location</span>
                  <span className={Styles.white}>US</span>
                  <div className={Styles.buttons} style={{ marginTop: '7px' }}>
                    <button
                      className={Styles.view}
                      onClick={(e) => controlAction(e, data.order_id, true)}
                    >
                      View
                    </button>
                    <button
                      className={Styles.reset}
                      onClick={() => handleButtonReset(data.order_id)}
                    >
                      Reset Password
                    </button>
                    <button
                      className={Styles.download}
                      onClick={(e) => controlAction(e, data.order_id, false)}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductList
