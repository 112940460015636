import React, { useState, useEffect } from 'react'
import Styles from './isp.module.scss'
import { icon_items } from '../../../pages/ResiDashboard/icon-item'
import Logo from '../../../assets/images/stripe.png'
import checkCircle from '../../../assets/images/signIn/check-circle.png'
import AnimationButton from '../../UI/AnimationButton'
import AnimationButtonGo from 'components/UI/AnimationButtonGo'
import { planPrices } from 'constants/hybridPLacPrices'

const Hybrid = (props) => {
  const { membershipType, cookies, user } = props
  const [loadingISP, setLoadingISP] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('')
  const [selectedPassword, setSelectedPassword] = useState('')
  const [selectedFeature, setSelectedFeature] = useState([])
  const [selectedAmount, setSelectedAmount] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [calculatedPrice, setCalculatedPrice] = useState('')
  const [fromPrice, setFromPrice] = useState('0.2')
  const [pricePerProxy, setPricePerProxy] = useState('')
  const [proxyType, setProxyType] = useState('isp')
  const [ispStatus, setISPstatus] = useState('')
  const [dcStatus, setDcStatus] = useState('')
  const [PaymentMethod, setPaymentMethod] = useState('stripe')
  const [button1Clicked, setButton1Clicked] = useState(true)
  const [button2Clicked, setButton2Clicked] = useState(false)
  const [planLengths, setPlanLengths] = useState([''])
  const [planBandwidths, setPlanBandwidths] = useState([''])
  const [selectedDuration, setSelectedDuration] = useState('')
  const [totalPrice, setTotalPrice] = useState('0')
  const [referral, setReferral] = useState(null)

  useEffect(() => {
    window.addEventListener('Rewardful.initialized', function () {
      setReferral(window.Rewardful.referral)
    })
  }, [])

  useEffect(() => {
    if (selectedRegion && selectedAmount && selectedDate && selectedDuration) {
      setTotalPrice(
        planPrices[selectedRegion][selectedAmount][selectedDate][
          selectedDuration
        ]
      )
    } else {
      setTotalPrice('0')
    }
  }, [selectedRegion, selectedAmount, selectedDate, selectedDuration])

  const plans_features = {
    LemonLive: [
      'Hybrid Solution Between ISP and Residential',
      'Low Data Usage',
      'Roll Back Data',
    ],
  }

  const plans_amounts = ['200', '300', '500']

  const amount_days = {
    200: [
      { label: '45 day', value: '45' },
      { label: '60 day', value: '60' },
    ],
    300: [
      { label: '45 day', value: '45' },
      { label: '60 day', value: '60' },
    ],
    500: [
      { label: '45 day', value: '45' },
      { label: '60 day', value: '60' },
    ],
  }

  const amount_data = {
    200: [{ label: '5 GB', value: '5' }],
    300: [{ label: '10 GB', value: '10' }],
    500: [{ label: '20 GB', value: '20' }],
  }

  const hybrid_region_list = [
    { code: '', label: 'Choose a Region' },
    { code: 'US', label: 'United States' },
    // { code: 'USELITE', label: 'United States Elite' },
    { code: 'JP', label: 'Japan' },
    { code: 'GB', label: 'United Kingdom' },
    { code: 'DE', label: 'Germany' },
  ]

  const setProxyStatus = (proxyType, stockData) => {
    const setStatus = (status) => {
      switch (proxyType) {
        case 'isp':
          setISPstatus(status)
          break
        case 'dc':
          setDcStatus(status)
          break
        default:
        // Handle unknown proxy types
      }
    }

    setStatus(stockData[proxyType] === 'instock')
  }

  const checkAllProxyStocks = () => {
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/checkStock', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: accessToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const proxyTypes = ['isp', 'dc']
        proxyTypes.forEach((proxyType) => {
          setProxyStatus(proxyType, data)
        })

        props.setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        alert('Error checking stock, please try again.')
      })
  }

  const setInitialPlan = (data) => {
    const plan = document.getElementById('plan_static').value
    setSelectedPlan(plan)
    setSelectedFeature(plans_features[plan])
    setCalculatedPrice('0')
  }

  useEffect(() => {
    checkAllProxyStocks()
  }, [])

  const handleSelectedDuration = (event) => {
    const selectedDuration = event.target.value
    setSelectedDuration(selectedDuration)
  }

  const handleSelectedPassword = (event) => {
    const selectedPassword = event.target.value
    setSelectedPassword(selectedPassword)
  }

  const handleSelectedRegion = (event) => {
    const selectedRegion = event.target.value
    setSelectedRegion(selectedRegion)
  }

  const handleSelectedAmount = (event) => {
    const selectedAmount = event.target.value
    setPlanLengths(amount_days[selectedAmount])
    setPlanBandwidths(amount_data[selectedAmount])
    setSelectedAmount(selectedAmount)
  }

  const handleSelectedDate = (event) => {
    const selectedDate = event.target.value
    setSelectedDate(selectedDate)
  }

  const handleISPPurchase = () => {
    if (selectedRegion === '') {
      alert('Please select region')
      return
    }

    if (
      (selectedRegion === 'USELITE' ||
        selectedRegion === 'JP' ||
        selectedRegion === 'DE' ||
        selectedRegion === 'GB') &&
      selectedPassword === ''
    ) {
      alert('Please provide password')
      return
    }

    if (selectedAmount === '') {
      alert('Please select Amount')
      return
    }

    if (selectedDate === '') {
      alert('Please select Date')
      return
    }

    setLoadingISP(true)
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/createLemonLiveOrder', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: `${accessToken}`,
        password: `${selectedPassword}`,
        proxy_amount: `${selectedAmount}`,
        bandwidth_amount: `${selectedDuration}`,
        expiration_days: `${selectedDate}`,
        payment_method: PaymentMethod,
        region: `${selectedRegion}`,
        referral: referral,
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setLoadingISP(false)
        window.location.href = `${data['purchase_url']}`
      })
      .catch((error) => {
        alert('Error')
        setLoadingISP(false)
        console.error(error)
      })
  }

  const handleBilling = () => {
    const accessToken = cookies.get('access_token')
    fetch(
      'https://dashboard-api.lemonclub.io/api/v1/createCustomerPortalSession',
      {
        method: 'POST',
        body: JSON.stringify({
          discord_token: `${accessToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data['portal_session_url']
      })
  }

  const handleButton1Click = () => {
    setPaymentMethod('stripe')
    setButton1Clicked(true)
    setButton2Clicked(false)
  }

  const handleButton2Click = () => {
    setPaymentMethod('crypto')
    setButton1Clicked(false)
    setButton2Clicked(true)
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.brand}>
        {icon_items.purchase}
        <span>Purchase Plans</span>
      </div>

      <div className={Styles.lab}>
        <span>LemonLive</span>

        <div className={Styles.labList}>
          {plans_features['LemonLive'].map((item, index) => (
            <div className={Styles.labItem} key={index}>
              <img src={checkCircle} alt="check-circle" />
              <div className={`main-text ${Styles.moreItemText}`}>{item}</div>
            </div>
          ))}
        </div>

        <div className={Styles.from}>Starting from</div>

        <div className={Styles.labInfo}>
          <span>$60/Plan</span>

          <AnimationButtonGo
            width="142px"
            height="32px"
            background="var(--re-white-button-bg)"
            color="var(--re-white-button-color)"
            size="13px"
            borderRadius="10px"
            button="dark"
            label="Learn More"
            goTo="https://discord.gg/lemonclub"
            isNewTab="_blank"
          />
        </div>

        <img className={Styles.logo} src={Logo} />
      </div>

      <div className={Styles.amount}>
        <div className={Styles.title}>
          {icon_items.data}

          <span>Data Amount</span>
        </div>

        <select
          className={Styles.selectPlan}
          value={selectedRegion}
          onChange={(event) => {
            handleSelectedRegion(event)
          }}
        >
          {hybrid_region_list.map((item) => (
            <option key={item.code} value={item.code}>
              {item.label}
            </option>
          ))}
        </select>
        {(selectedRegion === 'JP' ||
          selectedRegion === 'USELITE' ||
          selectedRegion === 'DE' ||
          selectedRegion === 'GB') && (
          <input
            className={Styles.selectPlan}
            placeholder="Enter Password"
            value={selectedPassword}
            onChange={(event) => {
              handleSelectedPassword(event)
            }}
            type="password"
          />
        )}

        <select
          id={'amount'}
          className={Styles.selectPlan}
          value={selectedAmount}
          onChange={(event) => {
            handleSelectedAmount(event)
          }}
        >
          <option disabled hidden value="">
            Amount
          </option>
          {plans_amounts.map((item) => (
            <option value={item} key={item}>
              {item} IPs
            </option>
          ))}
        </select>

        <select
          id={'date'}
          className={Styles.selectPlan}
          value={selectedDate}
          onChange={(event) => {
            handleSelectedDate(event)
          }}
          disabled={selectedAmount === ''}
        >
          <option disabled hidden value="">
            Length
          </option>
          {planLengths.map((item) => (
            <option disabled={!ispStatus} value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </select>

        <select
          id={'amount'}
          className={Styles.selectPlan}
          value={selectedDuration}
          onChange={(event) => {
            handleSelectedDuration(event)
          }}
          disabled={selectedDate === ''}
        >
          <option disabled hidden value="">
            Data
          </option>
          {planBandwidths.map((item) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </select>

        <div className={Styles.dataLabel}>
          <span>Total</span>
          <span></span>
        </div>

        <div className={Styles.price}>
          <span>${totalPrice}</span>
          <span></span>
        </div>

        <div className={Styles.button}>
          <AnimationButton
            width="calc((100% - 11px) / 2)"
            height="42px"
            background="var(--re-white-yellow-bg)"
            color="#FFF"
            size="16px"
            borderRadius="12px"
            button="yellow"
            label={loadingISP ? 'Loading...' : 'Purchase'}
            handler={handleISPPurchase}
          />

          <AnimationButton
            width="calc((100% - 11px) / 2)"
            height="42px"
            background="var(--re-black-yellow-bg)"
            color="var(--font-color)"
            size="16px"
            borderRadius="12px"
            button="special"
            label="Manage Renewal"
            handler={handleBilling}
          />
        </div>
      </div>
    </div>
  )
}

export default Hybrid
