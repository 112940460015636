import React, { useState, useEffect } from 'react'
import Styles from './productList.module.scss'
import Modal from 'components/UI/Modal'
import Cstyles from '../DetailModal/DetailModal.module.scss'
import ContentStyles from '../DetailModal/ModalContent/modalContent.module.scss'
import AnimationButton from 'components/UI/AnimationButton'
import ListStyles from '../modalContentList/modalContentList.module.scss'
import { errorHandler, successHandler } from 'helpers'
import { icon_items } from 'pages/ResiDashboard/icon-item'
import Copy from '../../../assets/images/copy.svg'

const ProductList = (props) => {
  const { cookies } = props
  const [sideDrawer, setSideDrawer] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [responseData, setResponseData] = useState([])
  const [modalData, setModalData] = useState([])

  const handleCopyOne = (e) => {
    var imgElement = e.target
    var parentDiv = imgElement.parentNode.parentNode
    var spanElement = parentDiv.querySelector('span')
    const proxyText = spanElement.innerHTML
    navigator.clipboard.writeText(proxyText)
    successHandler('Successfully Copied!')
  }

  const handleCopyGen = () => {
    const proxyText = modalData.join('\n')
    navigator.clipboard.writeText(proxyText)
    successHandler('Successfully Copied!')
  }

  const handleDownloadGen = () => {
    const proxyText = modalData.join('\n')
    const blob = new Blob([proxyText], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'proxies.txt'
    link.click()
    URL.revokeObjectURL(url)
  }

  const moment = require('moment')

  const getUserDCOrders = () => {
    const accessToken = cookies.get('access_token')
    fetch('https://dashboard-api.lemonclub.io/api/v1/getUserStaticOrders', {
      method: 'POST',
      body: JSON.stringify({
        discord_token: `${accessToken}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data) {
          if (data) {
            data.sort((a, b) => {
              const dateA = moment(a.date_created, 'HH:mm DD/MM/YYYY')
              const dateB = moment(b.date_created, 'HH:mm DD/MM/YYYY')
              return dateB - dateA
            })
            setResponseData(data)
          }
        }
        // props.setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching orders:', error)
      })
  }

  useEffect(() => {
    getUserDCOrders()
  }, [])

  const toggleModal = (e) => {
    setShowSettings(!showSettings)
    setSideDrawer(false)
  }

  const controlAction = (e, order_id, isView) => {
    setModalData([])
    const accessToken = cookies.get('access_token')
    e.preventDefault()
    fetch('https://dashboard-api.lemonclub.io/api/v1/viewStaticProxies', {
      method: 'POST',
      body: JSON.stringify({
        order_id: order_id,
        discord_token: `${accessToken}`,
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        const dataArray = data.split('\n')
        setModalData(dataArray)

        setTimeout(() => {
          if (isView) {
            toggleModal()
          } else {
            const proxyText = data
            const blob = new Blob([proxyText], { type: 'text/plain' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = 'proxies.txt'
            link.click()
            URL.revokeObjectURL(url)
          }
        }, 100)
      })
      .catch(console.error)
  }

  const handleButtonReset = (orderId) => {
    const accessToken = cookies.get('access_token')
    fetch(
      'https://dashboard-api.lemonclub.io/api/v1/resetStaticProxyPassword',
      {
        method: 'POST',
        body: JSON.stringify({
          discord_token: `${accessToken}`,
          order_id: `${orderId}`,
        }),
      }
    )
      .then(() => {
        successHandler('Password Changed Successfully!')
      })
      .catch((error) => {
        console.error('Error:', error)
        errorHandler('Error changing password, please try again later!')
      })
  }

  return (
    <div
      className={`proxy-list ${Styles.proxy_container}`}
      style={{ overflow: 'scroll' }}
    >
      <div className={Styles.proxy_list}>
        <tr className={`${Styles.proxy} ${Styles.proxy_ref}`}>
          <th style={{ width: '20.11%' }}>Product</th>
          <th style={{ width: '9.84%' }}>Quantity</th>
          <th style={{ width: '16.9%' }}>Date</th>
          <th style={{ width: '21.8%' }}>Expiration</th>
          <th style={{ width: '14.33%' }}>Location</th>
          <th style={{ width: '17.02%', minWidth: '175px' }}>Actions</th>
        </tr>
        <div className={Styles.desktop}>
          {responseData.map((data, index) => (
            <tr
              className={`${Styles.proxy} ${Styles.proxy_content}`}
              key={index}
            >
              <td style={{ width: '21.11%' }}>
                <span className={Styles.ProductName}>
                  {data.proxy_type === 'growtopia' ? 'Growtopia' : 'LemonClub'}
                </span>{' '}
                <span className={Styles.ProductType}>
                  {data.proxy_type === 'growtopia'
                    ? 'SOCKS'
                    : data.proxy_type === 'isp'
                    ? 'ISP'
                    : 'DC'}
                </span>
              </td>
              <td style={{ width: '8.84%' }}>{data.quantity}</td>
              <td style={{ width: '16.9%' }}>{data.date_created}</td>
              <td style={{ width: '21.8%' }}>
                <div
                  className={
                    !data.isExpired ? Styles.freeDateLabel : Styles.expiredLabel
                  }
                  style={{ marginLeft: '10px' }}
                >
                  {!data.isExpired ? `in ${data.time_left}` : 'Expired'}
                </div>
              </td>
              <td style={{ width: '14.33%', color: 'var(--font-color)' }}>
                {data.region}
              </td>
              <td style={{ width: '17.02%', minWidth: '175px' }}>
                <div className={Styles.buttons}>
                  <button
                    className={Styles.view}
                    onClick={(e) => controlAction(e, data.order_id, true)}
                  >
                    View
                  </button>
                  <button
                    className={Styles.reset}
                    onClick={() => handleButtonReset(data.order_id)}
                  >
                    Reset Password
                  </button>
                  <button
                    className={Styles.download}
                    onClick={(e) => controlAction(e, data.order_id, false)}
                  >
                    Download
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </div>

        {showSettings ? (
          <Modal show={showSettings} variant={Cstyles.modal}>
            <div
              className={Cstyles.container}
              style={{ width: '75%', margin: 'auto' }}
            >
              <div
                className={`analytics-container ${ContentStyles.analytics_container}`}
              >
                <div className={`${ListStyles.proxy_container}`}>
                  <div className={ListStyles.proxy_option}>
                    {icon_items.generated_proxy}

                    <span>Generated Proxies</span>

                    <AnimationButton
                      width="132px"
                      height="32px"
                      background="var(--re-white-button-bg)"
                      color="var(--re-white-button-color)"
                      size="13px"
                      borderRadius="10px"
                      button="dark"
                      label="Copy All"
                      handler={handleCopyGen}
                    />

                    <AnimationButton
                      width="142px"
                      height="32px"
                      background="linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%)"
                      color="#FFF"
                      size="13px"
                      borderRadius="10px"
                      button="yellow"
                      label="Download"
                      handler={handleDownloadGen}
                    />
                  </div>

                  <div className={ListStyles.proxy_list}>
                    {modalData.map((item, index) => (
                      <div className={ListStyles.proxy} key={index}>
                        <span>{item}</span>

                        <div className={ListStyles.copy_icon}>
                          <img src={Copy} alt="copy" onClick={handleCopyOne} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={ContentStyles.button}>
                  <AnimationButton
                    width="132px"
                    height="42px"
                    background="var(--re-black-yellow-bg)"
                    color="var(--font-color)"
                    size="16px"
                    borderRadius="12px"
                    button="special"
                    label="Close"
                    handler={(e) => toggleModal(e)}
                  />
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        <div className={Styles.mobile}>
          {responseData.map((data, index) => (
            <div className={Styles.productCardContainer} key={index}>
              <div className={Styles.productCard}>
                <div className={Styles.product_head}>
                  <span className={Styles.product_name}>
                    <span className={Styles.product_headLabel}>
                      {data.product_head}{' '}
                    </span>{' '}
                    {data.product_name}
                  </span>
                  <span className={`${Styles.quantity} ${Styles.right}`}>
                    <span className={Styles.quantity_No}>{data.quantity}</span>{' '}
                    ISPs
                  </span>
                </div>

                <div className={`${Styles.product_head} ${Styles.day}`}>
                  <span className={Styles.day_label}>Date</span>
                  <span className={Styles.white}>{data.date_created}</span>
                  <span className={Styles.expired_label}>Expiration Date</span>
                  <div className={`${Styles.right} ${Styles.white}`}>
                    <div
                      className={`${
                        !data.isExpired
                          ? Styles.freeDateLabel
                          : Styles.expiredLabel
                      } ${Styles.marginRight}`}
                    >
                      {!data.isExpired ? `in ${data.time_left}` : 'Expired'}
                    </div>
                    {data.date_expiry}
                  </div>
                </div>

                <div className={`${Styles.product_head} ${Styles.location}`}>
                  <span className={Styles.day_label}>location</span>
                  <span className={Styles.white}>
                    {data.product_name.split(' ')[0]}
                  </span>
                  <div className={Styles.buttons} style={{ marginTop: '7px' }}>
                    <button
                      className={Styles.view}
                      onClick={(e) => controlAction(e, data.order_id, true)}
                    >
                      View
                    </button>
                    <button
                      className={Styles.reset}
                      onClick={() => handleButtonReset(data.order_id)}
                    >
                      Reset Password
                    </button>
                    <button
                      className={Styles.download}
                      onClick={(e) => controlAction(e, data.order_id, false)}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductList
