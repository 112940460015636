import React, { useState, useEffect } from 'react'
import Styles from './analytics.module.scss'
import { icon_items } from '../../../pages/ResiDashboard/icon-item'
import SelectStyles from '../../UI/SelectInput/SelectInput.module.scss'
import AnimationButton from 'components/UI/AnimationButton'
import ProxyListStyles from '../proxyList/proxyList.module.scss'
import { successHandler } from 'helpers'
import Copy from '../../../assets/images/copy.svg'
import { saveAs } from 'file-saver'

import { connect, useDispatch } from 'react-redux'

const Index = ({ cookies, proxyLists }) => {
  const [plans, setPlans] = useState([])
  const [remainingData, setRemainingData] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [selectedStart, setSelectedStart] = useState('')
  const [selectedEnd, setSelectedEnd] = useState('')
  const dispatch = useDispatch()

  const setSelectedPlan = (selectedPlan) => {
    dispatch({ type: 'SELECT_PLAN', payload: selectedPlan })
  }

  //fetch resi order
  useEffect(() => {
    async function fetchUserData() {
      try {
        const accessToken = cookies.get('access_token')
        const response = await fetch(
          'https://dashboard-api.lemonclub.io/api/v1/fetchUserResidentialOrders',
          {
            method: 'POST',
            body: JSON.stringify({
              discord_token: `${accessToken}`,
            }),
          }
        )
        const data = await response.json()
        const filteredPlans = data.filter(
          (plan) => plan[Object.keys(plan)]['totalData'] > 0
        )
        setPlans(filteredPlans)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchUserData()
  }, [])

  const handleSelectStart = (event) => {
    console.log(event.target.value)
    setSelectedStart(event.target.value)
  }

  const handleSelectEnd = (event) => {
    console.log(event.target.value)
    setSelectedEnd(event.target.value)
  }

  const handlePlanChangeUsage = (event) => {
    const selectedPlanUsage = event.target.value

    if (selectedPlanUsage === 'Choose Plan') {
      setRemainingData(0)
      setTotalData(0)
      setSelectedPlan('')
    } else {
      const plan = plans.find(
        (plan) => Object.keys(plan)[0] === selectedPlanUsage
      )
      if (plan) {
        let remainingData = plan[selectedPlanUsage]['totalData']

        setRemainingData(Math.round(remainingData * 100) / 100)
        setTotalData(
          plan[selectedPlanUsage]['totalData'] +
            plan[selectedPlanUsage]['usedData']
        )
        setSelectedPlan(selectedPlanUsage)
      } else {
        setRemainingData(0)
        setTotalData(0)
        setSelectedPlan('')
      }
    }
  }

  const handleCopyOne = (e) => {
    var imgElement = e.target
    var parentDiv = imgElement.parentNode.parentNode
    var spanElement = parentDiv.querySelector('span')
    const proxyText = spanElement.innerHTML
    navigator.clipboard.writeText(proxyText)
    successHandler('Successfully Copied!')
  }

  const handleCopyGen = () => {
    const proxyText = proxyLists.join('\n')
    navigator.clipboard.writeText(proxyText)
    successHandler('Successfully Copied!')
  }

  const handleDownloadGen = () => {
    const proxyText = proxyLists.join('\n')
    const blob = new Blob([proxyText], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'proxies.txt'
    link.click()
    URL.revokeObjectURL(url)
  }

  const formatSize = (d) => {
    if (!d) return `${(0).toFixed(2)} GB`
    d /= 1073741824
    if (Math.abs(d) < 0.5) {
      return `${(d * 1024).toFixed(2)} MB`
    } else if (Math.abs(d) > 1024) {
      return `${(d / 1024).toFixed(2)} TB`
    }
    return `${d.toFixed(2)} GB`
  }

  const handleDownloadAnalyticsOverall = async () => {
    if (selectedStart === '') {
      alert('Please select start date')
      return
    }

    if (selectedEnd === '') {
      alert('Please select end date')
      return
    }

    const accessToken = cookies.get('access_token')
    const response = await fetch(
      'https://dashboard-api.lemonclub.io/api/v1/fetchResidentialAnalyticsOverall',
      {
        method: 'POST',
        body: JSON.stringify({
          discord_token: `${accessToken}`,
          start: `${selectedStart}`,
          end: `${selectedEnd}`,
          offset: 0,
          limit: 1000,
        }),
      }
    )

    if (!response.ok) {
      alert('Failed to fetch data')
      return
    }

    const data = await response.json()

    if (!Array.isArray(data)) {
      alert('Invalid data format')
      return
    }

    const csvRows = [
      'timestamp,requests,data',
      ...data.map(
        (row) => `${row.timestamp},${row.requests},${formatSize(row.data)}`
      ),
    ]

    const csvContent = csvRows.join('\n')
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, 'analytics.csv')
  }

  const handleDownloadAnalyticsDomains = async () => {
    if (selectedStart === '') {
      alert('Please select start date')
      return
    }

    if (selectedEnd === '') {
      alert('Please select end date')
      return
    }

    const accessToken = cookies.get('access_token')
    let allData = []
    let offset = 0
    const limit = 1000

    try {
      while (true) {
        const response = await fetch(
          'https://dashboard-api.lemonclub.io/api/v1/fetchResidentialAnalyticsDomains',
          {
            method: 'POST',
            body: JSON.stringify({
              discord_token: `${accessToken}`,
              start: `${selectedStart}`,
              end: `${selectedEnd}`,
              offset,
              limit,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        if (!response.ok) {
          alert('Failed to fetch data')
          return
        }

        const data = await response.json()

        if (!Array.isArray(data)) {
          alert('Invalid data format')
          return
        }

        allData = allData.concat(data)

        if (data.length < limit) {
          break
        }

        offset += limit
      }

      const csvRows = [
        'hostname,requests,data',
        ...allData.map(
          (row) => `${row.hostname},${row.requests},${formatSize(row.data)}`
        ),
      ]

      const csvContent = csvRows.join('\n')
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      saveAs(blob, 'analytics.csv')
    } catch (error) {
      console.error('Error fetching data:', error)
      alert('An error occurred while fetching data')
    }
  }

  return (
    <div className={`analytics-container ${Styles.analytics_container}`}>
      <div className={Styles.plan}>
        <div className={Styles.bland}>
          <div className={Styles.bland_icon}>
            {icon_items.analytics}

            <span className={Styles.brand_title}> Plan Analytics</span>
          </div>

          <span className={Styles.brand_description}>
            Here you can see the plan info, and generate proxies.
          </span>
        </div>

        <div className={Styles.option}>
          <div className={Styles.brand_label}>Plan</div>

          <select
            onChange={(event) => handlePlanChangeUsage(event)}
            className={SelectStyles.select}
            style={{ backgroundColor: 'var(--re-select-dark-bg)' }}
          >
            <option>Choose Plan</option>
            {plans.map((item) => (
              <option
                key={
                  Object.keys(item)[0] === 'IPRoyal'
                    ? 'LemonBlue'
                    : Object.keys(item)[0]
                }
                value={Object.keys(item)[0]}
              >
                {Object.keys(item)[0] === 'IPRoyal'
                  ? 'LemonBlue'
                  : Object.keys(item)[0]}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className={Styles.data_value}>
        <div className={Styles.data_left}>
          <div className={Styles.data_label}>
            <span>Data Left</span>

            <div className={Styles.left_rate}>
              {icon_items.rate_arrow}

              <span>
                {(totalData ? (remainingData / totalData) * 100 : 0).toFixed(2)}
                %
              </span>
            </div>
          </div>

          <span className={Styles.capacity}>{remainingData}GB</span>

          <div className={Styles.progress}>
            <div
              style={{ width: `${(remainingData / totalData) * 100}%` }}
            ></div>
          </div>
        </div>

        <div className={Styles.data_used}>
          <div className={Styles.data_label}>
            <span>Data Used</span>

            <div className={Styles.left_rate}>
              {icon_items.rate_arrow}

              <span>
                {(totalData
                  ? ((totalData - remainingData) / totalData) * 100
                  : 0
                ).toFixed(2)}
                %
              </span>
            </div>
          </div>

          <div>
            <span className={Styles.today}>All Time</span>
          </div>

          <div style={{ marginTop: '-7px' }}>
            <span className={Styles.capacity}>
              {(totalData - remainingData).toFixed(2)}GB
            </span>
          </div>
        </div>
      </div>

      <div className={Styles.data_range}>
        <div className={Styles.option}>
          <div className={Styles.label}>From</div>
          <input
            type="date"
            max={new Date().toISOString().split('T')[0]}
            onChange={handleSelectStart}
          />
        </div>
        <div className={Styles.option}>
          <div className={Styles.label}>To</div>
          <input type="date" onChange={handleSelectEnd} />
        </div>
        <div className={Styles.footer}>
          <AnimationButton
            width="182px"
            height="32px"
            background="linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%)"
            color="#FFF"
            size="13px"
            borderRadius="10px"
            button="yellow"
            label="Download Domains"
            handler={handleDownloadAnalyticsDomains}
          />
          <AnimationButton
            width="182px"
            height="32px"
            background="linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%)"
            color="#FFF"
            size="13px"
            borderRadius="10px"
            button="yellow"
            label="Download Overall"
            handler={handleDownloadAnalyticsOverall}
          />
        </div>
      </div>

      <div className={Styles.horizontal}></div>

      <div className={`proxy-list ${ProxyListStyles.proxy_container}`}>
        <div
          className={ProxyListStyles.proxy_option}
          style={{ marginTop: '0px !important' }}
        >
          {icon_items.list}

          <span>Generated Proxies</span>

          <AnimationButton
            width="132px"
            height="32px"
            background="var(--re-white-button-bg)"
            color="var(--re-white-button-color)"
            size="13px"
            borderRadius="10px"
            button="dark"
            label="Copy All"
            handler={handleCopyGen}
          />

          <AnimationButton
            width="142px"
            height="32px"
            background="linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%)"
            color="#FFF"
            size="13px"
            borderRadius="10px"
            button="yellow"
            label="Download"
            handler={handleDownloadGen}
          />
        </div>

        <div className={ProxyListStyles.proxy_list}>
          {proxyLists.map((item, index) => (
            <div className={ProxyListStyles.proxy} key={index}>
              <span>{item}</span>

              <div>
                <img src={Copy} alt="copy" onClick={handleCopyOne} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  proxyLists: state.proxyLists,
})

export default connect(mapStateToProps)(Index)
