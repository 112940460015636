import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import styles from './DashboardLayout.module.scss'
import Navigation from './Navigation'
import Sidebar from './Sidebar'
import SideDrawer from './SideDrawer/SideDrawer'
import { ToastContainer } from 'react-toastify'
import { useUser } from 'services/auth'
import { errorHandler, successHandler } from 'helpers'
import Confetti from 'react-confetti'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'react-query/constants'
import LocalStorage from 'shared/utils/LocalStorage'
import { items } from './Sidebar/SidebarItems/items'

const DashboardLayout = () => {
  const [sideDrawer, setSideDrawer] = useState(false)
  const { data, logout, isLoading } = useUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showConfetti, setShowConfetti] = useState(false)
  const [text, setText] = useState('Residential Proxies')
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toggleHandler = () => setSideDrawer(!sideDrawer)

  useEffect(() => {
    const paymentStatus = searchParams.get('paymentStatus')

    if (!paymentStatus) return
    if (paymentStatus === 'success') {
      successHandler('Payment Successful! 🎉 Thank you for your purchase.')
      setShowConfetti(true)
      queryClient.invalidateQueries([queryKeys.USER])

      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-16910145004/vK37CJSZwqYaEOyrsf8-',
          value: 1.0,
          currency: 'USD',
          transaction_id: '',
        })
      }
    } else if (paymentStatus === 'failed') {
      errorHandler('Payment Failed ❌ Please try again or contact support.')
    }
    searchParams.delete('paymentStatus')
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams, queryClient, navigate])

  useEffect(() => {
    const productToBuy = JSON.parse(
      localStorage.getItem(LocalStorage.productToBuy)
    )

    if (showConfetti) {
      navigate(productToBuy?.pathname)
      const confettiTimeout = setTimeout(() => {
        setShowConfetti(false)
      }, 6000)
      return () => clearTimeout(confettiTimeout)
    }
  }, [showConfetti, navigate])

  if (isLoading) return <div />

  // if (!data && !isLoading) return <Navigate to='/login' />;
  const getText = (selectedText) => {
    setText(selectedText)
    setSideDrawer(!sideDrawer)
  }

  return (
    <div className={styles.layout}>
      {showConfetti ? (
        <Confetti
          gravity={0.2}
          width={window.innerWidth - 25}
          height={window.innerHeight}
        />
      ) : null}
      <ToastContainer />

      <SideDrawer
        show={sideDrawer}
        closeClicked={toggleHandler}
        logout={logout}
        user={data}
        menu={items}
        handler={getText}
      />

      <div className={styles.content}>
        <Sidebar menu={items} handler={getText} />
        <div className={styles.rightContainer}>
          <Navigation
            sideDrawer={sideDrawer}
            clickHandler={toggleHandler}
            logout={logout}
            user={data}
            label={text}
          />
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
